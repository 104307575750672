import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import getInitials from "../../utils/getInitials";
import { RateSetListResponse } from "../../api/rateSet";
import { Loadable } from "../Loading/Loadable";
import formatDate from "../../utils/formatDate";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";

/** Props for the RateSetListResults component */
interface RateSetListResultsProps {
  /** List of rate sets that will be displayed in the list */
  rateSetsResponse: Loadable<RateSetListResponse>;
  /** Function to execute when a rate set entry is clicked */
  onClick: (rateSetId: string) => void;
}

const RateSetListResults = ({
  rateSetsResponse,
  onClick,
  children,
  ...rest
}: React.PropsWithChildren<RateSetListResultsProps>) => {
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Date Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <LoadingWrapper
                loadingInfo={rateSetsResponse}
                loadingComponent={<LoadingTableRow colSpan={4} />}
              >
                {rateSetsResponse?.data?.rate_sets?.map((rateSet) => (
                  <TableRow
                    hover
                    key={rateSet.external_id}
                    onClick={() => onClick(rateSet.external_id)}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Avatar src={""} sx={{ mr: 2 }}>
                          {getInitials(rateSet.name)}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {rateSet.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{rateSet.description}</TableCell>
                    <TableCell>
                      {formatDate(rateSet.created_date_utc)}
                    </TableCell>
                    <TableCell>
                      {formatDate(rateSet.last_modified_date_utc)}
                    </TableCell>
                  </TableRow>
                ))}
              </LoadingWrapper>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {children}
    </Card>
  );
};

export default RateSetListResults;
