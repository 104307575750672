import { Card, CardContent, Grid, TextField } from "@mui/material";
import { EditableRateCard } from "../../api/rateCard";

interface RateCardEditProps {
  value: EditableRateCard;
  onChange: (newValue: EditableRateCard) => void;
}

const RateCardEdit = ({ value, onChange, ...rest }: RateCardEditProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form autoComplete="off" noValidate {...rest}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the name"
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={value.name}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please describe the rate card"
                label="Description"
                name="description"
                onChange={handleChange}
                value={value.description}
                variant="outlined"
                multiline={true}
                rows={4}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RateCardEdit;
