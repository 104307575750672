import React from "react";
import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CreatableRateSet } from "../../api/rateSet";
import RateSetDetailsToolbar from "../../components/rateSets/RateSetDetailsToolbar";
import RateSetCreate from "../../components/rateSets/RateSetCreate";
import { useLoadingInfoState } from "../../components/Loading/useLoadableState";
import { useSnackbar } from "notistack";
import { performAsyncAction } from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import useApiClient from "../../api/useApiClient";

const NewRateSet = () => {
  const navigate = useNavigate();
  const [creatableRateSet, setCreatableRateSet] =
    React.useState<CreatableRateSet>({
      external_id: "",
      name: "",
      effective_date_utc: "",
    });

  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  return (
    <>
      <Helmet>
        <title>New Rate Set</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <RateSetDetailsToolbar
            name="New Rate Set"
            canDelete={false}
            editing={creatableRateSet !== undefined}
            onEdit={() => {
              // Do nothing
            }}
            onDelete={() => {
              // Do nothing
            }}
            onSave={() => {
              if (creatableRateSet) {
                performAsyncAction(
                  () => client.rate.set.create(creatableRateSet),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/rateSets")
                );
              }
            }}
            onCancel={() => navigate("/app/rateSets")}
          />
          <Box sx={{ pt: 3 }}>
            <RateSetCreate
              value={creatableRateSet}
              onChange={(x) => setCreatableRateSet(x)}
            />
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default NewRateSet;
