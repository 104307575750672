import React from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { RateTableItem } from "../../api/rateTable";
import { Loadable } from "../Loading/Loadable";

interface RateTableChildrenProps {
  rateTables: Loadable<RateTableItem[]>;
}

const RateTableChildren = ({ rateTables }: RateTableChildrenProps) => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleLimitChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => {
    const newPageLimit = parseInt(event?.target.value || "", 10);

    setLimit(isNaN(newPageLimit) ? 25 : newPageLimit);
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Child Rate Tables</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rateTables.data?.slice(0, limit)?.map((rateTable) => (
                  <TableRow hover key={rateTable.external_id}>
                    <TableCell>
                      <Link to={`/app/rateTable/${rateTable.external_id}`}>
                        {rateTable.name}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={Number(rateTables.data?.length) || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardContent>
    </Card>
  );
};

export default RateTableChildren;
