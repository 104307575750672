import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet-async";
import { ApiClient } from "../../api/client";
import {
  PublishedVersionListResponse,
  CurrentVersion,
  emptyCurrentVersion,
} from "../../api/publishing";
import useApiClient from "../../api/useApiClient";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import {
  performAsyncAction,
  useAsync,
} from "../../components/Loading/useAsync";
import {
  useLoadableState,
  useLoadingInfoState,
} from "../../components/Loading/useLoadableState";
import CurrentVersionDisplay from "../../components/publishing/CurrentVersionDisplay";
import PublishedVersionListResults from "../../components/publishing/PublishedVersionListResults";
import PublishingToolbar from "../../components/publishing/PublishingToolbar";

const PublishingDetails = () => {
  const [publishedVersions, setPublishedVersions] =
    useLoadableState<PublishedVersionListResponse>({
      publishes: [],
    });
  const [currentVersion, setCurrentVersion] =
    useLoadableState<CurrentVersion>(emptyCurrentVersion);
  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const publish = () => {
    if (window.confirm("Are you sure you want to publish the carrier data?")) {
      performAsyncAction(
        () => client.publishing.publish(),
        setLoadingInfo,
        enqueueSnackbar,
        () => {
          client.publishing
            .getAll()
            .then((x) =>
              setPublishedVersions({
                state: "finished",
                data: { publishes: x.publishes },
              })
            )
            .catch((err) => console.error(err));
          client.publishing
            .getCurrent()
            .then((x) =>
              setCurrentVersion({
                state: "finished",
                data: {
                  zoneChart: x.zoneChart,
                  rateCards: x.rateCards,
                },
              })
            )
            .catch((err) => console.error(err));
        }
      );
    }
  };

  const getAllPublishes = React.useCallback(
    (apiClient: ApiClient) => apiClient.publishing.getAll(),
    []
  );

  const getCurrentVersion = React.useCallback(
    (apiClient: ApiClient) => apiClient.publishing.getCurrent(),
    []
  );

  useAsync(getAllPublishes, setPublishedVersions);
  useAsync(getCurrentVersion, setCurrentVersion);

  return (
    <>
      <Helmet>
        <title>Published Versions</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <PublishingToolbar name="Current Version" onPublish={publish} />
          <Box sx={{ pt: 3 }}>
            <CurrentVersionDisplay
              currentVersion={currentVersion}
            ></CurrentVersionDisplay>
          </Box>
        </Container>

        <Container maxWidth={false}>
          <Box sx={{ mt: 5 }}>
            <Typography variant="h1">Version History</Typography>
          </Box>
          <Box sx={{ pt: 3 }}>
            <PublishedVersionListResults
              versions={publishedVersions}
            ></PublishedVersionListResults>
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default PublishingDetails;
