import React from "react";
import { Box, Container, TablePagination } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { ApiClient } from "../../api/client";
import { ZoneFileListResponse } from "../../api/zoneFile";
import ListToolbar from "../../components/ListToolbar";
import { useAsync } from "../../components/Loading/useAsync";
import { useLoadableState } from "../../components/Loading/useLoadableState";
import ZoneFileListResults from "../../components/zoneFiles/ZoneFileListResults";

const ZoneFileList = () => {
  const navigate = useNavigate();
  const [zoneFiles, setZoneFiles] = useLoadableState<ZoneFileListResponse>({
    zone_files: [],
    total_count: 0,
  });

  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const getAllZoneFiles = React.useCallback(
    (client: ApiClient) => client.zone.file.getAll(page, limit),
    [page, limit]
  );

  const handleLimitChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => {
    const newPageLimit = parseInt(event?.target.value || "", 10);

    setLimit(isNaN(newPageLimit) ? 25 : newPageLimit);
    setPage(0);
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useAsync(getAllZoneFiles, setZoneFiles);

  return (
    <>
      <Helmet>
        <title>Zone File List</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ListToolbar
            title="Zone Files"
            newButtonTitle="Add zone file"
            newUrl="/app/zoneFiles/new"
          />
          <Box sx={{ pt: 3 }}>
            <ZoneFileListResults
              zoneFiles={zoneFiles}
              onClick={(x) => navigate(`/app/zoneFile/${x}`)}
            >
              <TablePagination
                component="div"
                count={Number(zoneFiles.data.total_count) || 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </ZoneFileListResults>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ZoneFileList;
