import { Button } from "@mui/material";

interface EditControlProps {
  editing: boolean;
  canDelete: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

const EditControls = ({
  editing,
  canDelete,
  onEdit,
  onSave,
  onCancel,
  onDelete,
}: EditControlProps) => {
  return editing ? (
    <>
      <Button
        sx={{ ml: 2 }}
        color="secondary"
        variant="contained"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        sx={{ ml: 2 }}
        color="primary"
        variant="contained"
        onClick={onSave}
      >
        Save
      </Button>
    </>
  ) : (
    <>
      {!editing && canDelete && (
        <Button
          sx={{ ml: 2 }}
          color="error"
          variant="contained"
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
      <Button
        sx={{ ml: 2 }}
        color="primary"
        variant="contained"
        onClick={onEdit}
      >
        Edit
      </Button>
    </>
  );
};

export default EditControls;
