import {
  ApiRounded,
  KeyboardDoubleArrowRightRounded,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import CarrierContext from "./CarrierContext";

const Logo = (props: React.PropsWithoutRef<{}>) => {
  const selectedCarrier = useContext(CarrierContext);
  const carrierName = selectedCarrier.carrier.name
    ? `${selectedCarrier.carrier.name}`
    : "Select Carrier";

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
        {...props}
      >
        <ApiRounded sx={{ color: "white" }} fontSize="large" />
        <Typography color="white" variant="h2" sx={{ pl: 1 }}>
          Native Rating - {carrierName}
          {carrierName && carrierName === "Select Carrier" && (
            <KeyboardDoubleArrowRightRounded
              sx={{ ml: 1, verticalAlign: "middle" }}
            />
          )}
        </Typography>
      </Box>
    </>
  );
};

export default Logo;
