import React, { useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";

interface LogicEditorProps {
  code: string;
  onUploadCode: (code: string) => void;
}

const LogicEditor = ({ code, onUploadCode }: LogicEditorProps) => {
  const [currentValue, setCurrentValue] = useState(code);

  return (
    <>
      <Card>
        <CardContent>
          <Typography color="textPrimary" variant="h5" sx={{ pb: 1 }}>
            View or Update Code.
          </Typography>
          <Box
            style={{
              maxHeight: 800,
              overflowY: "scroll",
            }}
          >
            <CodeEditor
              value={code}
              language="js"
              placeholder="Please enter Rating code."
              onChange={(evn: { target: { value: React.SetStateAction<string> } }) => setCurrentValue(evn.target.value)}
              padding={15}
              style={{
                fontSize: 14,
                fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Button
              color="primary"
              variant="contained"
              disabled={currentValue === code}
              onClick={() => {
                if (window.confirm(`Are you sure you want to update the rating code?`)) {
                  onUploadCode(currentValue);
                }
              }}
            >
              Update Code
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default LogicEditor;
