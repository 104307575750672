import NewRateCard from "./NewRateCard";
import RateCardCode from "./RateCardCode";
import RateCardDetails from "./RateCardDetails";
import RateCardList from "./RateCardList";
import RateCardVersionDetails from "./RateCardVersionDetails";

const rateCardRoutes = [
  {
    path: "rateCards",
    exact: true,
    element: RateCardList,
  },
  {
    path: "rateCards/new",
    exact: true,
    element: NewRateCard,
  },
  {
    path: "rateCard/:id",
    exact: true,
    element: RateCardDetails,
  },
  { path: "rateCard/:id/code", exact: true, element: RateCardCode },
  {
    path: "rateCard/:id/version/:versionId",
    exact: true,
    element: RateCardVersionDetails,
  },
];

export default rateCardRoutes;
