type CompareFn<T> = (a: T, b: T) => number;

const negate =
  <T>(sorter: CompareFn<T>) =>
  (a: T, b: T): number => {
    const result = sorter(a, b);
    return result === 0 ? result : result * -1;
  };

/** Create a string comparison function using the specified property
 * @param map Function to access the property that should be sorted on
 * @param opts Optional parameters for the comparison function
 * @returns Comparison function that can be used in a sort method
 */
export const byString = <T>(
  map: (item: T) => string,
  opts?: { order?: "asc" | "desc" }
): CompareFn<T> => {
  const options = { order: "asc", ...opts };

  const sorter = (a: T, b: T): number => {
    const left = map(a);
    const right = map(b);

    if (left < right) {
      return -1;
    }

    if (left > right) {
      return 1;
    }

    return 0;
  };

  return options.order === "asc" ? sorter : negate(sorter);
};

/** Create a number comparison function using the specified property
 * @param map Function to access the property that should be sorted on
 * @param opts Optional parameters for the comparison function
 * @returns Comparison function that can be used in a sort method
 */
export const byNumber = <T>(
  map: (item: T) => number,
  opts?: { order?: "asc" | "desc" }
): CompareFn<T> => {
  const options = { order: "asc", ...opts };
  const sorter = (a: T, b: T): number => map(a) - map(b);
  return options.order === "asc" ? sorter : negate(sorter);
};
