import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { CurrentVersion } from "../../api/publishing";
import { Loadable } from "../Loading/Loadable";
import LoadingBox from "../Loading/LoadingBox";
import LoadingWrapper from "../Loading/LoadingWrapper";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../utils/formatDate";

interface CurrentVersionDisplayProps {
  currentVersion: Loadable<CurrentVersion>;
}

const shadowBoxStyle = {
  boxShadow: "1px 2px 9px #B2BEB5",
  mt: 2,
};

const CurrentVersionDisplay = ({ currentVersion }: React.PropsWithoutRef<CurrentVersionDisplayProps>) => {
  return (
    <Card>
      <CardContent>
        <LoadingWrapper loadingInfo={currentVersion} loadingComponent={<LoadingBox />}>
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              {currentVersion?.data?.rateCards?.length && (
                <Grid item lg={6} md={6} xs={12}>
                  <Box>
                    <Typography variant="h3">Rate Cards</Typography>
                  </Box>
                  <Box sx={shadowBoxStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>External Id</TableCell>
                          <TableCell>Version</TableCell>
                          <TableCell>Published</TableCell>
                          <TableCell>Transaction Id</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentVersion.data.rateCards.map((rateCard) => (
                          <TableRow hover key={rateCard.externalId}>
                            <TableCell>
                              <Link title="view this rate card" to={`/app/rateCard/${rateCard.externalId}`}>
                                {rateCard.externalId}
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link
                                title="view this rate card version"
                                to={`/app/rateCard/${rateCard.externalId}/version/${rateCard.version}`}
                              >
                                {rateCard.version}
                              </Link>
                            </TableCell>
                            <TableCell>{formatDateTime(rateCard.datePublished)}</TableCell>
                            <TableCell>{rateCard.transactionId}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              )}
              {currentVersion?.data?.zoneChart?.version && (
                <Grid item lg={6} md={6} xs={12}>
                  <Box>
                    <Typography variant="h3">Zone</Typography>
                  </Box>
                  <Box sx={shadowBoxStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Version</TableCell>
                          <TableCell>Date Published</TableCell>
                          <TableCell>Transaction Id</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover key={currentVersion.data.zoneChart.version}>
                          <TableCell>{currentVersion.data.zoneChart?.version}</TableCell>
                          <TableCell>{formatDateTime(currentVersion.data.zoneChart?.datePublished)}</TableCell>
                          <TableCell>{currentVersion.data.zoneChart?.transactionId}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              )}
              {!currentVersion?.data?.rateCards?.length && !currentVersion?.data?.zoneChart?.version && (
                <Box>
                  <Typography color="textPrimary" variant="subtitle1" fontStyle="italic">
                    No current version
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </LoadingWrapper>
      </CardContent>
    </Card>
  );
};

export default CurrentVersionDisplay;
