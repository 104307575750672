import React from "react";
import { Avatar, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

interface UserInfo {
  avatar: string;
  name: string;
  email: string;
}

const emptyUserInfo: UserInfo = {
  avatar: "/static/images/avatars/avatar_6.png",
  name: "",
  email: "",
};

const UserDetails = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = React.useState(emptyUserInfo);

  React.useEffect(() => {
    if (!authState?.isAuthenticated) {
      setUserInfo(emptyUserInfo);
    } else {
      void oktaAuth.getUser().then((x) =>
        setUserInfo({
          ...emptyUserInfo,
          name: x.name || "No name",
          email: x.email || "No email",
        })
      );
    }
  }, [authState, oktaAuth]);

  return (
    <>
      <Avatar
        component={RouterLink}
        src={userInfo.avatar}
        sx={{
          cursor: "pointer",
          width: 64,
          height: 64,
        }}
        to="/app/account"
      />
      <Typography color="textPrimary" variant="h5">
        {userInfo.name}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {userInfo.email}
      </Typography>
    </>
  );
};

export default UserDetails;
