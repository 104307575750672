import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { RateCardVersionListResponse } from "../../api/publishing";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import { Loadable } from "../Loading/Loadable";
import { formatDateTime } from "../../utils/formatDate";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { Link } from "react-router-dom";

/** Props for the RateCardVersions component */
interface RateCardVersionsProps {
  /** List of rate card versions that will be displayed */
  versions: Loadable<RateCardVersionListResponse>;
}

const RateCardVersions = ({ versions }: React.PropsWithoutRef<RateCardVersionsProps>) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rate Card Versions</TableCell>
                <TableCell>Published</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <LoadingWrapper loadingInfo={versions} loadingComponent={<LoadingTableRow colSpan={4} />}>
                {versions?.data?.publishes
                  ?.map((version) => (
                    <TableRow hover key={version.version}>
                      <TableCell>
                        <Link to={`/app/rateCard/${version.externalId}/version/${version.version}`}>
                          {version.version}
                        </Link>
                        {version.latest ? (
                          <Tooltip title="Latest Version">
                            <NewReleasesIcon
                              color="info"
                              fontSize="small"
                              sx={{ marginLeft: 1, verticalAlign: "middle" }}
                            />
                          </Tooltip>
                        ) : null}
                      </TableCell>
                      <TableCell>{formatDateTime(version.datePublished)}</TableCell>
                    </TableRow>
                  ))
                  .slice(0, 10)}
              </LoadingWrapper>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default RateCardVersions;
