import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Badge, Box, Hidden, IconButton, Toolbar } from "@mui/material";
import { Input, Menu, Notifications } from "@mui/icons-material";
import Logo from "./Logo";
import { useOktaAuth } from "@okta/okta-react";

interface DashboardNavbarProps {
  onMobileNavOpen: React.MouseEventHandler<HTMLButtonElement>;
}

const DashboardNavbar = ({
  onMobileNavOpen,
  ...rest
}: DashboardNavbarProps) => {
  const { oktaAuth } = useOktaAuth();
  const [notifications] = useState([]);

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/app/carriers" title="Native Rating Home">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <IconButton color="inherit" size="large">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <Notifications />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            size="large"
            onClick={() => oktaAuth.signOut()}
          >
            <Input />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
