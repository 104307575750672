import { Box, Card, CardContent, Typography, Modal } from "@mui/material";
import { SxProps, Theme } from "@mui/system";

const modalStyle: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  overflow: "auto",
  maxHeight: "400",
};

interface DialogBoxProps {
  description?: string;
  open: boolean;
  onClose: () => void;
  title: string;
}

const DialogBox = ({
  children,
  description,
  onClose,
  open,
  title,
}: React.PropsWithChildren<DialogBoxProps>) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Card>
          <CardContent>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {description}
            </Typography>

            {children}
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default DialogBox;
