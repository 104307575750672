import React from "react";
import { Box, Container, TablePagination } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { ApiClient } from "../../api/client";
import { RateCardListResponse } from "../../api/rateCard";
import ListToolbar from "../../components/ListToolbar";
import { useAsync } from "../../components/Loading/useAsync";
import { useLoadableState } from "../../components/Loading/useLoadableState";
import RateCardListResults from "../../components/rateCards/RateCardListResults";

const RateCardList = () => {
  const navigate = useNavigate();
  const [rateCardsResponse, setRateCardsResponse] =
    useLoadableState<RateCardListResponse>({
      rate_cards: [],
      total_count: 0,
    });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const getPagedRateCardsResponse = React.useCallback(
    (client: ApiClient) => client.rate.card.getAll(page, rowsPerPage),
    [page, rowsPerPage]
  );

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => {
    const newRowsPerPage = parseInt(event?.target.value || "", 10);

    setRowsPerPage(isNaN(newRowsPerPage) ? 25 : newRowsPerPage);
    setPage(0);
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useAsync(getPagedRateCardsResponse, setRateCardsResponse);

  return (
    <>
      <Helmet>
        <title>Rate Card List</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ListToolbar
            title="Rate Cards"
            newButtonTitle="Add rate card"
            newUrl="/app/rateCards/new"
          />
          <Box sx={{ pt: 3 }}>
            <RateCardListResults
              rateCardsResponse={rateCardsResponse}
              onClick={(x) => navigate(`/app/rateCard/${x}`)}
            >
              <TablePagination
                component="div"
                count={Number(rateCardsResponse?.data?.total_count) || 10}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </RateCardListResults>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default RateCardList;
