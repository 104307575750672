import {
  Box,
  Button,
  Card,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";
import { RateCardItem } from "../../api/rateCard";
import { Loadable } from "../Loading/Loadable";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingBox from "../Loading/LoadingBox";
import { Variant } from "@mui/material/styles/createTypography";
import { NavLink as RouterLink } from "react-router-dom";
import { MonetizationOnRounded } from "@mui/icons-material";

interface RateCardDisplayProps {
  rateCard: Loadable<RateCardItem>;
}

interface DetailItemProps {
  title: string;
  value?: string;
  valueVariant?: Variant;
}

const DetailItem = (props: DetailItemProps) => (
  <Box paddingBottom="1rem">
    <Typography color="textSecondary" variant="caption">
      {props.title}
    </Typography>
    <Typography color="textPrimary" variant={props.valueVariant || "body2"}>
      {props.value}
    </Typography>
  </Box>
);

const RateCardDisplay = ({ rateCard, ...rest }: RateCardDisplayProps) => {
  return (
    <Card {...rest}>
      <CardContent>
        <LoadingWrapper
          loadingInfo={rateCard}
          loadingComponent={<LoadingBox />}
        >
          <Box
            sx={{
              alignItems: "left",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailItem
              title="Name"
              value={rateCard.data.name}
              valueVariant="h3"
            />
            <DetailItem title="Description" value={rateCard.data.description} />
            <DetailItem
              title="Created"
              value={rateCard.data.created_date_utc}
            />
            <DetailItem
              title="Last Modified"
              value={rateCard.data.last_modified_date_utc}
            />
            <Box sx={{ pt: 1 }}>
              <Tooltip title="Test rates using this rate card">
                <Button
                  component={RouterLink}
                  to={`/app/getRates?RateCard=${rateCard.data.external_id}`}
                  color="success"
                  variant="contained"
                  startIcon={<MonetizationOnRounded />}
                >
                  Test Rates
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </LoadingWrapper>
      </CardContent>
    </Card>
  );
};

export default RateCardDisplay;
