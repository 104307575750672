import { Download } from "@mui/icons-material";
import { Box, Card, CardContent, IconButton, Tooltip, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { RateCardVersionDetailResponse } from "../../api/publishing";
import { formatDateTime } from "../../utils/formatDate";
import { Loadable } from "../Loading/Loadable";
import LoadingBox from "../Loading/LoadingBox";
import LoadingWrapper from "../Loading/LoadingWrapper";

interface DetailItemProps {
  title: string;
  value?: string;
  valueVariant?: Variant;
}

const DetailItem = (props: React.PropsWithoutRef<DetailItemProps>) => (
  <Box paddingBottom="1rem">
    <Typography color="textSecondary" variant="caption">
      {props.title}
    </Typography>
    <Typography color="textPrimary" variant={props.valueVariant || "body2"}>
      {props.value}
    </Typography>
  </Box>
);

interface CodeDetailItemProps {
  title: string;
  codeType: string;
  codeVersion: string;
  valueVariant?: Variant;
  onDownload: () => void;
}

const CodeDetailItem = (props: React.PropsWithoutRef<CodeDetailItemProps>) => (
  <Box paddingBottom="1rem">
    <Typography color="textSecondary" variant="caption">
      {props.title}
    </Typography>
    <Typography color="textPrimary" variant={props.valueVariant || "body2"}>
      {props.codeType === "rate-card-specific" ? "Rate Card Specific" : "Carrier Default"}
    </Typography>
    <Typography color="textPrimary" variant={props.valueVariant || "body2"}>
      {props.codeVersion}
      <Tooltip title="Download Code">
        <IconButton onClick={props.onDownload} aria-label={`Download Code`} color="success" size="small">
          <Download />
        </IconButton>
      </Tooltip>
    </Typography>
  </Box>
);

interface RateCardVersionDisplayProps {
  rateCardVersionDetail: Loadable<RateCardVersionDetailResponse>;
  onDownloadData: () => Promise<Blob>;
  downloadFileName: string;
  codeType: string;
  codeVersion: string;
}

const RateCardVersionDisplay = ({
  rateCardVersionDetail,
  onDownloadData,
  downloadFileName,
  codeType,
  codeVersion,
}: React.PropsWithoutRef<RateCardVersionDisplayProps>) => {
  const handleDownload = () => {
    void onDownloadData().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = downloadFileName;
      a.click();
    });
  };

  return (
    <Card>
      <CardContent>
        <LoadingWrapper loadingInfo={rateCardVersionDetail} loadingComponent={<LoadingBox />}>
          <Box
            sx={{
              alignItems: "left",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailItem title="" value={rateCardVersionDetail?.data?.details?.externalId} valueVariant="h3" />
            <DetailItem title="Rate Card External Id" value={rateCardVersionDetail?.data?.details?.externalId} />
            <DetailItem title="Rate Card Version" value={rateCardVersionDetail?.data?.details?.version} />
            <CodeDetailItem
              onDownload={() => handleDownload()}
              title="Code Type & Version"
              codeType={codeType}
              codeVersion={codeVersion}
            />
            <DetailItem
              title="Date Published"
              value={formatDateTime(rateCardVersionDetail?.data?.details?.datePublished)}
            />
            <DetailItem title="Transaction Id" value={rateCardVersionDetail?.data?.details?.transactionId} />
          </Box>
        </LoadingWrapper>
      </CardContent>
    </Card>
  );
};

export default RateCardVersionDisplay;
