import {
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CarrierLogsResponse } from "../../api/carrier";
import { formatDateTime } from "../../utils/formatDate";
import { Loadable } from "../Loading/Loadable";

interface DownloadInfo {
  filename: string;
  onDownload: () => Promise<Blob>;
}

interface LogViewerProps {
  logs: Loadable<CarrierLogsResponse>;
  downloadInfo: DownloadInfo;
}

const LogViewer = ({ logs, downloadInfo }: LogViewerProps) => {
  const handleDownload = () => {
    void downloadInfo.onDownload().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = downloadInfo.filename;
      a.click();
    });
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ pb: 2 }}>
              <Typography color="textPrimary" gutterBottom variant="h4">
                Logs
              </Typography>
            </Box>
            <Box sx={{ pb: 2 }}>
              <Button color="primary" variant="contained" onClick={() => handleDownload()}>
                Download Logs
              </Button>
            </Box>
          </Box>
          <Box
            style={{
              marginTop: 10,
              maxHeight: 800,
              overflowY: "scroll",
              background: "#f5f5f5",
              border: 1,
              borderStyle: "solid",
              borderRadius: 2,
              borderColor: "gray",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>Level</TableCell>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Rate Card</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs?.data?.logs
                  ?.map((log) => (
                    <TableRow hover key={log.timestamp}>
                      <TableCell>{log.transactionId}</TableCell>
                      <TableCell>{log.level}</TableCell>
                      <TableCell>{formatDateTime(log.timestamp)}</TableCell>
                      <TableCell>{log.rateCard}</TableCell>

                      <TableCell>{log.message}</TableCell>
                    </TableRow>
                  ))
                  .slice(0, 100)}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default LogViewer;
