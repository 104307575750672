import React from "react";
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  IconButton,
  Checkbox,
  Typography,
  Icon,
} from "@mui/material";
import { Box } from "@mui/system";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DeleteRounded } from "@mui/icons-material";
import { Loadable } from "../Loading/Loadable";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import { ZoneSetItem } from "../../api/zoneSet";
import { Link } from "react-router-dom";
import formatDate from "../../utils/formatDate";
import DialogBox from "../DialogBox";
import useApiClient from "../../api/useApiClient";

interface AssociatedZoneSetListProps {
  zoneSets: Loadable<{ external_id: string; name: string }[]>;
  onAssociateZoneSets: (zoneSetIds: string[]) => Promise<void>;
  onDisassociateZoneSet: (zoneSetId: string) => Promise<void>;
}

const AssociatedZoneSetList = ({
  zoneSets,
  onAssociateZoneSets,
  onDisassociateZoneSet,
}: AssociatedZoneSetListProps) => {
  const client = useApiClient();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [allZoneSets, setAllZoneSets] = React.useState<
    ZoneSetItem[] | undefined
  >();
  const [selectedZoneSetExternalIds, setSelectedZoneSetExternalIds] =
    React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAssociateZoneSetSelectOne = (
    _: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const selectedIndex = selectedZoneSetExternalIds.indexOf(id);
    let newSelectedZoneSetIds: string[] = [];

    if (selectedIndex === -1) {
      newSelectedZoneSetIds = newSelectedZoneSetIds.concat(
        selectedZoneSetExternalIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedZoneSetIds = newSelectedZoneSetIds.concat(
        selectedZoneSetExternalIds.slice(1)
      );
    } else if (selectedIndex === selectedZoneSetExternalIds.length - 1) {
      newSelectedZoneSetIds = newSelectedZoneSetIds.concat(
        selectedZoneSetExternalIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedZoneSetIds = newSelectedZoneSetIds.concat(
        selectedZoneSetExternalIds.slice(0, selectedIndex),
        selectedZoneSetExternalIds.slice(selectedIndex + 1)
      );
    }

    setSelectedZoneSetExternalIds(newSelectedZoneSetIds);
  };

  const handleAssociateZoneSetSelectAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setSelectedZoneSetExternalIds(
        allZoneSets
          ?.filter(
            (x) => !zoneSets?.data?.find((y) => y.external_id === x.external_id)
          )
          .map((zoneSet) => zoneSet.external_id) || []
      );
    } else {
      setSelectedZoneSetExternalIds([]);
    }
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => {
    const newRowsPerPage = parseInt(event?.target.value || "", 10);

    setRowsPerPage(isNaN(newRowsPerPage) ? 25 : newRowsPerPage);
    setPage(0);
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  zoneSets?.data?.sort((a, b) => (a.name > b.name ? 1 : -1));
  allZoneSets?.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Zone Set
                    <IconButton
                      title="Associate zone sets"
                      color="success"
                      onClick={() => {
                        void client.zone.set
                          .getAll()
                          .then((x) => setAllZoneSets(x.zone_sets));
                        handleOpen();
                      }}
                    >
                      <Icon>add_circle</Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <LoadingWrapper
                  loadingInfo={zoneSets}
                  loadingComponent={<LoadingTableRow colSpan={2} />}
                >
                  {zoneSets?.data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((zoneSet) => (
                      <TableRow hover key={zoneSet.external_id}>
                        <TableCell>
                          <Link to={`/app/zoneSet/${zoneSet.external_id}`}>
                            {zoneSet.name}
                          </Link>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="disassociate"
                            title="Remove zone set association"
                            color="error"
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Are you sure you want to disassociate ${zoneSet.external_id} zone set?`
                                )
                              ) {
                                void onDisassociateZoneSet(zoneSet.external_id);
                              }
                            }}
                          >
                            <DeleteRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </LoadingWrapper>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={zoneSets?.data?.length || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
        <DialogBox
          open={open}
          onClose={handleClose}
          title="Associate Zone Sets"
        >
          Select the zone sets to associate.
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox onChange={handleAssociateZoneSetSelectAll} />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Date Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allZoneSets
                    ?.filter(
                      (x) =>
                        !zoneSets?.data?.find(
                          (y) => y.external_id === x.external_id
                        )
                    )
                    .map((zoneSet) => (
                      <TableRow key={zoneSet.external_id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedZoneSetExternalIds.indexOf(
                                zoneSet.external_id
                              ) !== -1
                            }
                            onChange={(event) =>
                              handleAssociateZoneSetSelectOne(
                                event,
                                zoneSet.external_id
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Typography color="textPrimary" variant="body1">
                              {zoneSet.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{zoneSet.description}</TableCell>
                        <TableCell>
                          {formatDate(zoneSet.created_date_utc)}
                        </TableCell>
                        <TableCell>
                          {formatDate(zoneSet.last_modified_date_utc)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Button
                sx={{ m: 2 }}
                variant="contained"
                disabled={!selectedZoneSetExternalIds?.length}
                onClick={() => {
                  void onAssociateZoneSets(selectedZoneSetExternalIds).then(
                    () => {
                      setSelectedZoneSetExternalIds([]);
                      handleClose();
                    }
                  );
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedZoneSetExternalIds([]);
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </PerfectScrollbar>
        </DialogBox>
      </CardContent>
    </Card>
  );
};

export default AssociatedZoneSetList;
