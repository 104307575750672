import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { EditableRateSet } from "../../api/rateSet";
import RateSetDetailsToolbar from "../../components/rateSets/RateSetDetailsToolbar";
import RateSetDisplay from "../../components/rateSets/RateSetDisplay";
import RateSetEdit from "../../components/rateSets/RateSetEdit";
import { RateSetItem } from "../../api/rateSet";
import RateSetRateTableList from "../../components/rateSets/RateSetRateTableList";
import { RateTableItem } from "../../api/rateTable";
import { useLoadableState, useLoadingInfoState } from "../../components/Loading/useLoadableState";
import { useSnackbar } from "notistack";
import { performAsyncAction, useAsync } from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import RateSetRateCardList from "../../components/rateSets/RateSetRateCardList";
import { RateCardItem } from "../../api/rateCard";
import useApiClient from "../../api/useApiClient";
import { ApiClient } from "../../api/client";

const RateSetDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };
  const [rateSet, setRateSet] = useLoadableState<RateSetItem>({
    external_id: "",
    name: "",
    effective_date_utc: "",
    expiration_date_utc: "",
  });
  const [rateTables, setRateTables] = useLoadableState<RateTableItem[]>([]);
  const [rateCards, setRateCards] = useLoadableState<RateCardItem[]>([]);
  const [editableRateSet, setEditableRateSet] = React.useState<EditableRateSet | undefined>(undefined);
  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const getRateSet = React.useCallback((client: ApiClient) => client.rate.set.get(id), [id]);
  const getRateTablesForRateSet = React.useCallback((client: ApiClient) => client.rate.set.getRateTables(id), [id]);
  const getRateCardsForRateSet = React.useCallback((client: ApiClient) => client.rate.set.getRateCards(id), [id]);

  useAsync(getRateSet, setRateSet);
  useAsync(getRateTablesForRateSet, setRateTables);
  useAsync(getRateCardsForRateSet, setRateCards);

  return (
    <>
      <Helmet>
        <title>Rate Set Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <RateSetDetailsToolbar
            name={rateSet?.data.name || "Loading..."}
            canDelete={
              !!rateSet && !!rateTables && (rateTables.data.length || 0) === 0 && (rateCards.data.length || 0) === 0
            }
            editing={editableRateSet !== undefined}
            onEdit={() => {
              setEditableRateSet(
                rateSet && {
                  name: rateSet.data.name,
                  description: rateSet.data.description || undefined,
                  effective_date_utc: rateSet.data.effective_date_utc,
                  expiration_date_utc: rateSet.data.expiration_date_utc || undefined,
                }
              );
            }}
            onSave={() => {
              if (rateSet && editableRateSet) {
                performAsyncAction(
                  () => client.rate.set.update(id, editableRateSet),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => {
                    setRateSet({
                      ...rateSet,
                      data: {
                        ...rateSet.data,
                        ...editableRateSet,
                      },
                    });
                    setEditableRateSet(undefined);
                  }
                );
              }
            }}
            onCancel={() => {
              setEditableRateSet(undefined);
            }}
            onDelete={() => {
              if (window.confirm("Are you sure you want to delete this?")) {
                performAsyncAction(
                  () => client.rate.set.remove(id),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/rateSets")
                );
              }
            }}
          />
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                {rateSet &&
                  (editableRateSet ? (
                    <RateSetEdit value={editableRateSet} onChange={(x) => setEditableRateSet(x)} />
                  ) : (
                    <RateSetDisplay rateSet={rateSet} />
                  ))}
              </Grid>
              <Grid item container lg={8} md={6} xs={12} spacing={3} alignContent="flex-start">
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <RateSetRateCardList rateCards={rateCards} />
                </Grid>
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <RateSetRateTableList
                    rateTables={rateTables}
                    onAssociateRateTables={(rateTableIds) =>
                      performAsyncAction(
                        () => client.rate.set.associateRateTables(id, rateTableIds),
                        setLoadingInfo,
                        enqueueSnackbar,
                        () => performAsyncAction(getRateTablesForRateSet(client), setRateTables, enqueueSnackbar)
                      )
                    }
                    onDisassociateRateTable={(rateTableId) =>
                      performAsyncAction(
                        () => client.rate.set.disassociateRateTable(id, rateTableId),
                        setLoadingInfo,
                        enqueueSnackbar,
                        () => performAsyncAction(getRateTablesForRateSet(client), setRateTables, enqueueSnackbar)
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default RateSetDetails;
