import { v4 as uuidV4 } from "uuid";
import joinUrlParts from "../utils/joinUrlParts";
import CarrierNotSelectedError from "./CarrierNotSelectedError";
import carrier, { CarrierApi } from "./carrier";
import rateCard, { RateCardApi } from "./rateCard";
import rateSet, { RateSetApi } from "./rateSet";
import rateTable, { RateTableApi } from "./rateTable";
import zoneSet, { ZoneSetApi } from "./zoneSet";
import zoneFile, { ZoneFileApi } from "./zoneFile";
import publishing, { PublishingApi } from "./publishing";
import job, { JobApi } from "./job";
import getRates, { GetRatesApi } from "./getRates";

export type ApiFetch = {
  (path: string, init?: RequestInit | undefined): Promise<Response>;
  getUrl: (path: string) => string;
};

export interface ApiClient {
  carrier: CarrierApi;
  rate: {
    card: RateCardApi;
    set: RateSetApi;
    table: RateTableApi;
  };
  zone: {
    set: ZoneSetApi;
    file: ZoneFileApi;
  };
  publishing: PublishingApi;
  job: JobApi;
  rating: GetRatesApi;
}

/** Get a full url given a path
 * @param path Path for which to get a full url
 * @returns full url for the specified path
 */
export const getCarrierUrl = (carrierId?: string) => (path: string) => {
  if (!carrierId) {
    throw new CarrierNotSelectedError();
  }

  return joinUrlParts("/api", "carriers", carrierId, path);
};

const createClient = (accessToken: string, carrierId?: string) => {
  const fetchApi = (getUrl: (path: string) => string): ApiFetch => {
    const func = async (path: string, init?: RequestInit | undefined): Promise<Response> => {
      return await fetch(getUrl(path), {
        ...init,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
          "ShipStation-TransactionID": uuidV4(),
          ...init?.headers,
        },
      });
    };
    func.getUrl = getUrl;
    return func;
  };

  const basicClient = fetchApi((x) => joinUrlParts("/api", x));
  const carrierResourceClient = fetchApi(getCarrierUrl(carrierId));

  return {
    carrier: carrier(basicClient, carrierResourceClient),
    rate: {
      card: rateCard(carrierResourceClient),
      set: rateSet(carrierResourceClient),
      table: rateTable(carrierResourceClient),
    },
    zone: {
      set: zoneSet(carrierResourceClient),
      file: zoneFile(carrierResourceClient),
    },
    publishing: publishing(carrierResourceClient),
    job: job(basicClient, carrierResourceClient),
    rating: getRates(basicClient),
  };
};

export default createClient;
