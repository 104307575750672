import NavItem from "../NavItem";
import { Package as CarrierIcon } from "react-feather";

const CarrierSelection = () => {
  return (
    <NavItem
      href={`/app/carriers`}
      key={`Select carrier`}
      title="Select Carrier"
      icon={CarrierIcon}
    />
  );
};

export default CarrierSelection;
