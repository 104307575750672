import joinUrlParts from "../utils/joinUrlParts";
import { ApiFetch } from "./client";
import { ZoneSetItem } from "./zoneSet";
import { throwOnError } from "./throwOnError";

export interface ZoneFileListResponse {
  zone_files: ZoneFileItem[];
  total_count: number;
}
export interface ZoneFileItem {
  /**
   * A string to uniquely identify a zone file.
   */
  external_id: string;

  /** A user-friendly file name */
  name: string;

  /**
   * Optionally allows Carrier App to provide additional details about a zone file
   */
  description?: string;

  /** Date the zone file was created */
  created_date_utc?: string;

  /** Date the zone file was last modified */
  last_modified_date_utc?: string;

  /** Date the zone file was last synced */
  last_sync_date_utc?: string;

  /**
   * Specifying the external ID of another zone file allows this zone file to inherit the
   * zones of the base/referenced zone file. This provides the ability to override
   * zones in the base table. Useful when only a subset of zones are different between tables.
   */
  base_zone_file_external_id?: string;
  base_zone_file_name?: string;
}

export const emptyZoneFileItem: ZoneFileItem = {
  external_id: "",
  name: "",
};

export type CreatableZoneFile = { external_id: string } & EditableZoneFile;
export interface EditableZoneFile {
  name: string;
  description?: string;
  base_zone_file_external_id?: string;
}

export interface ZoneFileApi {
  getAll: (page?: number, itemsPerPage?: number) => Promise<ZoneFileListResponse>;
  get: (zoneFileId: string) => Promise<ZoneFileItem>;
  create: (newValue: CreatableZoneFile) => Promise<void>;
  update: (zoneFileId: string, newValue: EditableZoneFile) => Promise<void>;
  remove: (zoneFileId: string) => Promise<void>;
  getZoneSets: (zoneFileId: string) => Promise<ZoneSetItem[]>;
  associateZoneSets: (zoneFileId: string, zoneSetIds: string[]) => Promise<void>;
  disassociateZoneSet: (zoneFileId: string, zoneSetId: string) => Promise<void>;
  getChildren: (zoneFileId: string) => Promise<ZoneFileItem[]>;
  uploadData: (zoneFileId: string, data: unknown) => Promise<void>;
  downloadData: (zoneFileId: string, s3Version?: string) => Promise<Blob>;
}

const zoneFile = (client: ApiFetch) => {
  const getAll = async (page = 0, itemsPerPage = 10): Promise<ZoneFileListResponse> => {
    const results = await client(`zone-files?page=${page}&itemsPerPage=${itemsPerPage}`);

    await throwOnError(results);

    return await results.json();
  };

  const get = async (zoneFileId: string): Promise<ZoneFileItem> => {
    const results = await client(joinUrlParts("zone-files", zoneFileId));
    return await results.json();
  };

  const create = async (newValue: CreatableZoneFile): Promise<void> => {
    const results = await client("zone-files", {
      method: "POST",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const update = async (zoneFileId: string, newValue: EditableZoneFile): Promise<void> => {
    const results = await client(joinUrlParts("zone-files", zoneFileId), {
      method: "PUT",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const remove = async (zoneFileId: string): Promise<void> => {
    const results = await client(joinUrlParts("zone-files", zoneFileId), {
      method: "DELETE",
    });

    await throwOnError(results);
  };

  const getZoneSets = async (zoneFileId: string): Promise<ZoneSetItem[]> => {
    const results = await client(joinUrlParts("zone-files", zoneFileId, "zone-sets"));

    return await results.json();
  };

  const associateZoneSets = async (zoneFileId: string, zoneSetIds: string[]): Promise<void> => {
    const results = await client(joinUrlParts("zone-files", zoneFileId, "zone-sets"), {
      method: "PATCH",
      body: JSON.stringify({ zone_sets: zoneSetIds }),
    });

    await throwOnError(results);
  };

  const disassociateZoneSet = async (zoneFileId: string, zoneSetId: string): Promise<void> => {
    const results = await client(joinUrlParts("zone-files", zoneFileId, "zone-sets", zoneSetId), {
      method: "DELETE",
    });

    await throwOnError(results);
  };

  const getChildren = async (zoneFileId: string): Promise<ZoneFileItem[]> => {
    const results = await client(joinUrlParts("zone-files", zoneFileId, "child-zone-files"));

    await throwOnError(results);

    return await results.json();
  };

  const uploadData = async (zoneFileId: string, data: unknown): Promise<void> => {
    const results = await client(joinUrlParts("zone-files", zoneFileId, "zones"), {
      method: "POST",
      body: !data ? undefined : (data as BodyInit),
    });

    await throwOnError(results);
  };

  const downloadData = async (zoneFileId: string, version?: string): Promise<Blob> => {
    const queryParam = !version ? "" : `?version=${version}`;
    const results = await client(joinUrlParts("zone-files", zoneFileId, `zones${queryParam}`));

    await throwOnError(results);

    return results.blob();
  };

  return {
    getAll,
    get,
    create,
    update,
    remove,
    associateZoneSets,
    disassociateZoneSet,
    getZoneSets,
    getChildren,
    uploadData,
    downloadData,
  };
};

export default zoneFile;
