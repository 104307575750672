import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { RateCardItem } from "../../api/rateCard";
import { isValid } from "date-fns";
import { useLocation } from "react-router-dom";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

interface RequestHeadersProps {
  rateCards: RateCardItem[];
  onChange: (headers: HeadersInit) => void;
}

const RequestHeaders = ({
  rateCards,
  onChange,
  ...rest
}: RequestHeadersProps) => {
  const [selectedRateCard, setSelectedRateCard] = useState<string>("");
  const [selectedRequestDate, setSelectedRequestDate] =
    useState<string>("unpublished");
  const [pickerRequestDate, setPickerRequestDate] = useState<Date | null>(null);
  const [dateISO, setDateISO] = useState<string>("");
  const [selectedHeaders, setSelectedHeaders] = useState<HeadersInit>({
    "nativerating-ratecard": "",
    "nativerating-explain": "true",
    "nativerating-requestdate": "",
  });

  const [hidden, setHidden] = React.useState(true);

  const { search } = useLocation();

  const handleRateCardDDLChange = (event: SelectChangeEvent) => {
    setSelectedRateCard(event.target.value);
    onChange({
      ...selectedHeaders,
      "nativerating-ratecard": event.target.value,
    });
  };

  const handleRequestDateDDLChange = (event: SelectChangeEvent) => {
    setSelectedRequestDate(event.target.value);
    if (event.target.value === "Select a date") {
      onChange({
        ...selectedHeaders,
        "nativerating-requestdate": "",
      });
    } else {
      onChange({
        ...selectedHeaders,
        "nativerating-requestdate": event.target.value,
      });
    }
  };

  const handleDatePickerChange = (changedDate: Date | null) => {
    setPickerRequestDate(changedDate);
    if (changedDate && isValid(changedDate)) {
      setDateISO(changedDate.toISOString());
      onChange({
        ...selectedHeaders,
        "nativerating-requestdate": changedDate.toISOString(),
      });
    } else {
      setDateISO("");
      onChange({
        ...selectedHeaders,
        "nativerating-requestdate": "",
      });
    }
  };

  useEffect(() => {
    setSelectedHeaders({
      "nativerating-ratecard": selectedRateCard,
      "nativerating-explain": "true",
      "nativerating-requestdate":
        selectedRequestDate === "Select a date" ? dateISO : selectedRequestDate,
    });
  }, [selectedRateCard, selectedRequestDate, dateISO]);

  useEffect(() => {
    onChange(selectedHeaders);
  });

  useEffect(() => {
    let cardFromQueryString = new URLSearchParams(search).get("RateCard");
    let defaultCard = rateCards.at(0)?.external_id ?? "";

    if (cardFromQueryString) {
      defaultCard =
        rateCards.find((x) => x.external_id === cardFromQueryString)
          ?.external_id ?? defaultCard;
    }
    setSelectedRateCard(defaultCard);
  }, [rateCards, search]);

  return (
    <form autoComplete="off" noValidate {...rest}>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setHidden(!hidden)}
          >
            <Box>
              <Typography color="textPrimary" variant="h3">
                Headers
              </Typography>
            </Box>
            <Box>
              <Tooltip title={hidden ? "Expand headers" : "Collapse headers"}>
                <IconButton onClick={() => setHidden(!hidden)} size="small">
                  {hidden ? <ArrowDownward /> : <ArrowUpward />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Collapse in={!hidden}>
            <Box
              sx={{
                pt: 2,
                display: "flex",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <Box>
                <FormControl required>
                  <InputLabel id="rate-card-select-label">Rate Card</InputLabel>
                  <Select
                    labelId="rate-card-select-label"
                    id="rate-card-select"
                    value={selectedRateCard}
                    label="Rate Card"
                    onChange={handleRateCardDDLChange}
                  >
                    {rateCards?.map((card) => (
                      <MenuItem
                        key={card.external_id}
                        value={card.external_id}
                        title={card.description}
                      >
                        {card.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Please select a rate card</FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <InputLabel id="request-date-select-label">
                    Rate Card Version
                  </InputLabel>
                  <Select
                    labelId="request-date-select-label"
                    id="request-date-select"
                    value={selectedRequestDate}
                    label="Rate Card Version"
                    onChange={handleRequestDateDDLChange}
                  >
                    <MenuItem value="unpublished" title="unpublished data">
                      Unpublished - Draft
                    </MenuItem>
                    <MenuItem value="published" title="current">
                      Published - Current
                    </MenuItem>
                    <MenuItem
                      value="Select a date"
                      title="Select a date to rate the shipment for"
                    >
                      or Select a date...
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    Please select a rate card version
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                {selectedRequestDate === "Select a date" && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date "
                      value={pickerRequestDate}
                      onChange={(newValue) => {
                        handleDatePickerChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          helperText="Uses published rates as of this date"
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              </Box>
            </Box>
          </Collapse>
        </CardContent>
      </Card>
    </form>
  );
};

export default RequestHeaders;
