import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";

interface RateRequestEditorProps {
  request: string;
  onGetRates: (rateRequest: string) => void;
}

const RateRequestEditor = ({ request, onGetRates }: RateRequestEditorProps) => {
  const [currentValue, setCurrentValue] = useState<string>("");

  useEffect(() => setCurrentValue(request), [request]);

  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ pb: 2 }}>
              <Typography color="textPrimary" variant="h3">
                Body
              </Typography>
            </Box>
            <Box sx={{ pb: 2 }}>
              <Button
                color="primary"
                variant="contained"
                sx={{ mr: 2 }}
                href="/sampleData/sample_request.json"
                download
              >
                Download Sample
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onGetRates(currentValue)}
              >
                Get Rates
              </Button>
            </Box>
          </Box>
          <Box
            style={{
              maxHeight: 800,
              overflowY: "scroll",
            }}
          >
            <CodeEditor
              value={request}
              language="json"
              placeholder="Please enter Rate Request."
              onChange={(evn: {
                target: { value: React.SetStateAction<string> };
              }) => setCurrentValue(evn.target.value)}
              padding={15}
              style={{
                fontSize: 14,
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default RateRequestEditor;
