import NewZoneFile from "./NewZoneFile";
import ZoneFileDetails from "./ZoneFileDetails";
import ZoneFileList from "./ZoneFileList";

const zoneFileRoutes = [
  {
    path: "zoneFiles",
    exact: true,
    element: ZoneFileList,
  },
  {
    path: "zoneFiles/new",
    exact: true,
    element: NewZoneFile,
  },
  { path: "zoneFile/:id", exact: true, element: ZoneFileDetails },
];

export default zoneFileRoutes;
