import { ApiFetch } from "./client";

export interface GetRatesApi {
  getRatesCapi: (request: unknown, headers: HeadersInit) => Promise<any>;
}

const getRates = (basicClient: ApiFetch) => {
  const getRatesCapi = async (
    request: unknown,
    headers: HeadersInit
  ): Promise<any> => {
    const response = await basicClient("rates-capi", {
      method: "POST",
      headers: headers,
      body: !request ? undefined : (request as BodyInit),
    });

    return response
      .json()
      .then((data) => ({ status: response.status, body: data }));
  };

  return {
    getRatesCapi,
  };
};

export default getRates;
