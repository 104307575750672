import NewRateTable from "./NewRateTable";
import RateTableDetails from "./RateTableDetails";
import RateTableList from "./RateTableList";

const rateTableRoutes = [
  {
    path: "rateTables",
    exact: true,
    element: RateTableList,
  },
  {
    path: "rateTables/new",
    exact: true,
    element: NewRateTable,
  },
  { path: "rateTable/:id", exact: true, element: RateTableDetails },
];

export default rateTableRoutes;
