import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ZoneVersionDetail } from "../../api/publishing";
import formatDate from "../../utils/formatDate";
import { Link } from "react-router-dom";
import { Download } from "@mui/icons-material";

interface ZoneSetRowProps {
  rowSpan: number;
  externalId: string;
  effectiveDate: string;
  expirationDate: string;
  zoneFileExternalId: string;
}

const ZoneSetRow = (props: ZoneSetRowProps) => (
  <>
    <TableCell rowSpan={props.rowSpan}>
      <Link to={`/app/zoneSet/${props.externalId}`}>{props.externalId}</Link>
      <br />
      {formatDate(props.effectiveDate)} - {formatDate(props.expirationDate)}
    </TableCell>
    <TableCell>
      <Link to={`/app/zoneFile/${props.zoneFileExternalId}`}>{props.zoneFileExternalId}</Link>
    </TableCell>
  </>
);

interface DataDetailsProps {
  onDownload: () => void;
}

const DataDetails = (props: DataDetailsProps) => (
  <>
    <Typography color="textSecondary" textTransform={"uppercase"} variant="caption" title="zones">
      Zones
    </Typography>
    <Tooltip title={`Download zones`}>
      <IconButton onClick={props.onDownload} aria-label={`Download zones`} color="success" size="small">
        <Download />
      </IconButton>
    </Tooltip>
  </>
);

interface RateCardVersionZonesResultProps {
  rateCardVersionZones: ZoneVersionDetail[];
  onDownloadData: (externalId: string, version: string) => Promise<Blob>;
}

const RateCardVersionZonesResult = ({ rateCardVersionZones, onDownloadData }: RateCardVersionZonesResultProps) => {
  const groupedZoneSet = rateCardVersionZones.reduce<Record<string, ZoneVersionDetail[]>>((result, current) => {
    (result[current.zone_set_external_id] = result[current.zone_set_external_id] || []).push(current);
    return result;
  }, {});

  const handleDownload = (externalId: string, version: string) => {
    void onDownloadData(externalId, version).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${externalId}_zones_${version}.json`;
      a.click();
    });
  };

  return (
    <>
      <Card>
        <CardContent>
          <Table size="small" width="100%">
            <TableHead>
              <TableRow>
                <TableCell>Zone File</TableCell>
                <TableCell>Zone Set</TableCell>
                <TableCell align="center">Data Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedZoneSet).map(([key, zoneSets]) => (
                <>
                  <TableRow hover key={`${key}-${zoneSets[0].external_id}`}>
                    <ZoneSetRow
                      rowSpan={zoneSets.length}
                      externalId={key}
                      effectiveDate={zoneSets[0].effective_date_utc}
                      expirationDate={zoneSets[0].effective_date_utc}
                      zoneFileExternalId={zoneSets[0].external_id}
                    />
                    <TableCell align="center">
                      <DataDetails
                        onDownload={() => handleDownload(zoneSets[0].external_id, zoneSets[0].version?.s3Version)}
                      />
                    </TableCell>
                  </TableRow>
                  {zoneSets.slice(1)?.map((zoneFile: any) => (
                    <TableRow hover key={`${key}-${zoneFile.external_id}`}>
                      <TableCell>
                        <Link to={`/app/zoneFile/${zoneFile.external_id}`}>{zoneFile.external_id}</Link>
                      </TableCell>
                      <TableCell align="center">
                        <DataDetails
                          onDownload={() => handleDownload(zoneFile.external_id, zoneFile.version?.s3Version)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default RateCardVersionZonesResult;
