import { Box, Card, CardContent, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { Link } from "react-router-dom";
import { ZoneFileItem } from "../../api/zoneFile";
import formatDate from "../../utils/formatDate";
import { Loadable } from "../Loading/Loadable";
import LoadingBox from "../Loading/LoadingBox";
import LoadingWrapper from "../Loading/LoadingWrapper";

interface DetailItemProps {
  title: string;
  value?: string;
  valueVariant?: Variant;
}

const DetailItem = (props: DetailItemProps) => (
  <Box paddingBottom="1rem">
    <Typography color="textSecondary" variant="caption">
      {props.title}
    </Typography>
    <Typography color="textPrimary" variant={props.valueVariant || "body2"}>
      {props.value}
    </Typography>
  </Box>
);

interface LinkDetailItemProps {
  title: string;
  value?: string;
  valueVariant?: Variant;
  navigateTo: string;
}

const LinkDetailItem = (props: LinkDetailItemProps) => (
  <Box paddingBottom="1rem">
    <Typography color="textSecondary" variant="caption">
      {props.title}
    </Typography>
    <Link to={props.navigateTo}>
      <Typography variant={props.valueVariant || "body2"}>
        {props.value}
      </Typography>
    </Link>
  </Box>
);

interface ZoneFileDisplayProps {
  zoneFile: Loadable<ZoneFileItem>;
}

const ZoneFileDisplay = ({ zoneFile, ...rest }: ZoneFileDisplayProps) => {
  return (
    <Card {...rest}>
      <CardContent>
        <LoadingWrapper
          loadingInfo={zoneFile}
          loadingComponent={<LoadingBox />}
        >
          <Box
            sx={{
              alignItems: "left",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailItem
              title="Name"
              value={zoneFile.data.name}
              valueVariant="h3"
            />
            <DetailItem title="External Id" value={zoneFile.data.external_id} />
            <DetailItem title="Description" value={zoneFile.data.description} />

            {zoneFile.data.base_zone_file_external_id && (
              <LinkDetailItem
                title="Base Zone File"
                value={zoneFile.data.base_zone_file_name}
                navigateTo={`/app/zoneFile/${zoneFile.data.base_zone_file_external_id}`}
              />
            )}
            <DetailItem
              title="Created"
              value={formatDate(zoneFile.data.created_date_utc)}
            />
            <DetailItem
              title="Last Modified"
              value={formatDate(zoneFile.data.last_modified_date_utc)}
            />
          </Box>
        </LoadingWrapper>
      </CardContent>
    </Card>
  );
};

export default ZoneFileDisplay;
