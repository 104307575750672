import React from "react";
import {
  LinearProgress,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { JobItem } from "../../api/job";
import PerfectScrollbar from "react-perfect-scrollbar";

interface RunningJobListProps {
  jobs: JobItem[];
}

const RunningJobList = ({ jobs }: RunningJobListProps) => (
  <Card>
    <PerfectScrollbar>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Job Type</TableCell>
              <TableCell>Data Type</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Progress</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((x) => (
              <React.Fragment key={x.job_id}>
                <TableRow>
                  <TableCell>{x.job_type}</TableCell>
                  <TableCell>{x.data_type}</TableCell>
                  <TableCell>
                    <Link to={`/app/rateTable/${x.external_id}`}>
                      {x.external_id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {x.synced_items} / {x.total_items}
                    <LinearProgress
                      variant="determinate"
                      value={(x.synced_items / x.total_items) * 100}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  </Card>
);

export default RunningJobList;
