import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { CarrierListResponse } from "../../api/carrier";
import useApiClient from "../../api/useApiClient";
import CarrierContext from "../../components/CarrierContext";
import CarrierListResults from "../../components/carriers/CarrierListResults";

const CarrierList = () => {
  const client = useApiClient();
  const [carriers, setCarriers] = React.useState<CarrierListResponse>();
  const { change: changeCarrier } = React.useContext(CarrierContext);

  useEffect(() => {
    client.carrier
      .getAll()
      .then(setCarriers)
      .catch((err) => console.error(err));
  }, [client]);

  return (
    <>
      <Helmet>
        <title>Carrier List</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <h1>Carriers</h1>
          <Box sx={{ pt: 3 }}>
            <CarrierListResults
              carriersListResponse={carriers}
              onCarrierSelected={changeCarrier}
            ></CarrierListResults>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CarrierList;
