import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import getInitials from "../../utils/getInitials";
import { RateCardListResponse } from "../../api/rateCard";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import { Loadable } from "../Loading/Loadable";
import formatDate from "../../utils/formatDate";

/** Props for the RateCardListResults component */
interface RateCardListResultsProps {
  /** List of rate cards that will be displayed in the list */
  rateCardsResponse: Loadable<RateCardListResponse>;
  /** Function to execute when a rate card entry is clicked */
  onClick: (rateCardId: string) => void;
}

const RateCardListResults = ({
  rateCardsResponse,
  onClick,
  children,
  ...rest
}: React.PropsWithChildren<RateCardListResultsProps>) => {
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Date Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <LoadingWrapper
                loadingInfo={rateCardsResponse}
                loadingComponent={<LoadingTableRow colSpan={4} />}
              >
                {rateCardsResponse?.data?.rate_cards?.map((rateCard) => (
                  <TableRow
                    hover
                    key={rateCard.external_id}
                    onClick={() => onClick(rateCard.external_id)}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Avatar src={""} sx={{ mr: 2 }}>
                          {getInitials(rateCard.name)}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {rateCard.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{rateCard.description}</TableCell>
                    <TableCell>
                      {formatDate(rateCard.created_date_utc)}
                    </TableCell>
                    <TableCell>
                      {formatDate(rateCard.last_modified_date_utc)}
                    </TableCell>
                  </TableRow>
                ))}
              </LoadingWrapper>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {children}
    </Card>
  );
};

export default RateCardListResults;
