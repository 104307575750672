import React, { useState } from "react";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { CreatableRateSet } from "../../api/rateSet";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValid } from "date-fns";

interface RateSetCreateProps {
  value: CreatableRateSet;
  onChange: (newValue: CreatableRateSet) => void;
}

const RateSetCreate = ({ value, onChange, ...rest }: RateSetCreateProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null);
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  const handleEffectiveDateChange = (changedDate: Date | null) => {
    setEffectiveDate(changedDate);
    if (changedDate && isValid(changedDate)) {
      onChange({
        ...value,
        effective_date_utc: changedDate.toISOString(),
      });
    } else {
      onChange({
        ...value,
        effective_date_utc: "",
      });
    }
  };

  const handleExpirationDateChange = (changedDate: Date | null) => {
    setExpirationDate(changedDate);
    if (changedDate && isValid(changedDate)) {
      onChange({
        ...value,
        expiration_date_utc: changedDate.toISOString(),
      });
    } else {
      onChange({
        ...value,
        expiration_date_utc: undefined,
      });
    }
  };

  return (
    <form autoComplete="off" noValidate {...rest}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify an id of the rate set"
                label="External ID"
                name="external_id"
                onChange={handleChange}
                required
                value={value.external_id}
                variant="outlined"
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the name"
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={value.name}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please describe the rate set"
                label="Description"
                name="description"
                onChange={handleChange}
                value={value.description}
                variant="outlined"
                multiline={true}
                rows={4}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Effective Date"
                  value={effectiveDate}
                  onChange={(newValue) => {
                    handleEffectiveDateChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      helperText="Please specify the effective date"
                      name="effective_date_utc"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Expiration Date"
                  value={expirationDate}
                  minDate={effectiveDate}
                  onChange={(newValue) => {
                    handleExpirationDateChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText="Please specify the expiration date"
                      name="expiration_date_utc"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RateSetCreate;
