import { Box, Card, CardContent, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { Link } from "react-router-dom";
import { RateTableItem } from "../../api/rateTable";
import formatDate from "../../utils/formatDate";
import { Loadable } from "../Loading/Loadable";
import LoadingBox from "../Loading/LoadingBox";
import LoadingWrapper from "../Loading/LoadingWrapper";

interface DetailItemProps {
  title: string;
  value?: string;
  valueVariant?: Variant;
}

const DetailItem = (props: DetailItemProps) => (
  <Box paddingBottom="1rem">
    <Typography color="textSecondary" variant="caption">
      {props.title}
    </Typography>
    <Typography color="textPrimary" variant={props.valueVariant || "body2"}>
      {props.value}
    </Typography>
  </Box>
);

interface LinkDetailItemProps {
  title: string;
  value?: string;
  valueVariant?: Variant;
  navigateTo: string;
}

const LinkDetailItem = (props: LinkDetailItemProps) => (
  <Box paddingBottom="1rem">
    <Typography color="textSecondary" variant="caption">
      {props.title}
    </Typography>
    <Link to={props.navigateTo}>
      <Typography variant={props.valueVariant || "body2"}>
        {props.value}
      </Typography>
    </Link>
  </Box>
);

interface RateTableDisplayProps {
  rateTable: Loadable<RateTableItem>;
}

const RateTableDisplay = ({ rateTable, ...rest }: RateTableDisplayProps) => {
  return (
    <Card {...rest}>
      <CardContent>
        <LoadingWrapper
          loadingInfo={rateTable}
          loadingComponent={<LoadingBox />}
        >
          <Box
            sx={{
              alignItems: "left",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailItem
              title="Name"
              value={rateTable.data.name}
              valueVariant="h3"
            />
            <DetailItem
              title="External Id"
              value={rateTable.data.external_id}
            />
            <DetailItem
              title="Description"
              value={rateTable.data.description}
            />

            <DetailItem title="Currency" value={rateTable.data.currency_code} />
            {rateTable.data.base_rate_table_external_id && (
              <LinkDetailItem
                title="Base Table"
                value={rateTable.data.base_rate_table_name}
                navigateTo={`/app/rateTable/${rateTable.data.base_rate_table_external_id}`}
              />
            )}
            <DetailItem
              title="Created"
              value={formatDate(rateTable.data.created_date_utc)}
            />
            <DetailItem
              title="Last Modified"
              value={formatDate(rateTable.data.last_modified_date_utc)}
            />
          </Box>
        </LoadingWrapper>
      </CardContent>
    </Card>
  );
};

export default RateTableDisplay;
