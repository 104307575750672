import NewRateSet from "./NewRateSet";
import RateSetDetails from "./RateSetDetails";
import RateSetList from "./RateSetList";



const rateSetRoutes = [
  {
    path: "rateSets",
    exact: true,
    element: RateSetList,
  },
  {
    path: "rateSets/new",
    exact: true,
    element: NewRateSet,
  },
  { path: "rateSet/:id", exact: true, element: RateSetDetails },
];

export default rateSetRoutes;
