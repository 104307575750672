/** Basic error data */
type ErrorData = {
  /** Simple message describing the error */
  message?: string;

  details?: {
    [path: string]: {
      message: string;
      value?: unknown;
    };
  };
};

/** Get error data from the response, if any
 * @param response Response from the API from which to get error data
 * @returns A promise with error data, which could be empty
 */
const getErrorData = async (response: Response): Promise<ErrorData> => {
  try {
    return response.json();
  } catch (err) {
    console.error(err);
    return {};
  }
};

/** Throw an error if the response was not successful
 * @param response Response from the API
 */
export const throwOnError = async (response: Response): Promise<void> => {
  if (response.status < 400) {
    return;
  }

  const responseData = await getErrorData(response);

  if (response.status >= 500) {
    throw new Error("Server error");
  }

  if (response.status === 422) {
    let errMsg = responseData.message || "Validation error";

    if (responseData.details) {
      const detailMessages = Object.entries(responseData.details)
        .flatMap(([, error]) => error.message)
        .join(", ");
      errMsg = `${errMsg}: ${detailMessages}`;
    }

    throw new Error(errMsg);
  }

  if (response.status >= 400) {
    throw new Error(responseData.message || "Client error");
  }
};
