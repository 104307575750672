import React from "react";
import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CreatableZoneSet } from "../../api/zoneSet";
import ZoneSetDetailsToolbar from "../../components/zoneSets/ZoneSetDetailsToolbar";
import ZoneSetCreate from "../../components/zoneSets/ZoneSetCreate";
import { useLoadingInfoState } from "../../components/Loading/useLoadableState";
import { useSnackbar } from "notistack";
import { performAsyncAction } from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import useApiClient from "../../api/useApiClient";

const NewZoneSet = () => {
  const navigate = useNavigate();
  const [creatableZoneSet, setCreatableZoneSet] =
    React.useState<CreatableZoneSet>({
      external_id: "",
      name: "",
      effective_date_utc: "",
    });

  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  return (
    <>
      <Helmet>
        <title>New Zone Set</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ZoneSetDetailsToolbar
            name="New Zone Set"
            canDelete={false}
            editing={creatableZoneSet !== undefined}
            onEdit={() => {
              // Do nothing
            }}
            onDelete={() => {
              // Do nothing
            }}
            onSave={() => {
              if (creatableZoneSet) {
                performAsyncAction(
                  () => client.zone.set.create(creatableZoneSet),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/zoneSets")
                );
              }
            }}
            onCancel={() => navigate("/app/zoneSets")}
          />
          <Box sx={{ pt: 3 }}>
            <ZoneSetCreate
              value={creatableZoneSet}
              onChange={(x) => setCreatableZoneSet(x)}
            />
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default NewZoneSet;
