import {
  Box,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext } from "react";
import { CarrierDetails, CarrierListResponse } from "../../api/carrier";
import CarrierContext from "../CarrierContext";

interface CarrierListResultsProps {
  carriersListResponse: CarrierListResponse | undefined;
  onCarrierSelected: (carrier: CarrierDetails) => void;
}

const CarrierListResults = ({
  carriersListResponse,
  onCarrierSelected,
}: React.PropsWithChildren<CarrierListResultsProps>) => {
  const selectedCarrier = useContext(CarrierContext);

  return (
    <Box sx={{ minWidth: 1050 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Manage</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>API Code</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {carriersListResponse?.carriers?.map((carrier) => (
            <TableRow
              hover
              key={carrier.id}
              selected={selectedCarrier.carrier.id === carrier.id}
            >
              <TableCell>
                <Switch
                  color="primary"
                  size="medium"
                  checked={selectedCarrier.carrier.id === carrier.id}
                  onClick={() => onCarrierSelected(carrier)}
                />
              </TableCell>
              <TableCell>{carrier.name}</TableCell>
              <TableCell>{carrier.id}</TableCell>
              <TableCell>{carrier.apiCodes?.toString()}</TableCell>
              <TableCell>{carrier.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default CarrierListResults;
