import joinUrlParts from "../utils/joinUrlParts";
import { ApiFetch } from "./client";
import { RateSetItem } from "./rateSet";
import { throwOnError } from "./throwOnError";

export interface RateTableListResponse {
  rate_tables: RateTableItem[];
  total_count: number;
}

export interface RateTableItem {
  /**
   * A string to uniquely identify a rate table.
   */
  external_id: string;

  /** A user-friendly table name */
  name: string;

  /**
   * Optionally allows Carrier App to provide additional details about a rate table
   */
  description?: string;

  /**
   * ISO 3217 value to specify the currency of the associated rates and discounts.
   */
  currency_code: string;

  /** Date the rate table was created */
  created_date_utc?: string;

  /** Date the rate was last modified */
  last_modified_date_utc?: string;

  /** Date the rate was last modified */
  last_sync_date_utc?: string;

  /**
   * Specifying the external ID of another rate table allows this rate table to inherit the
   * rates/charges of the base/referenced rate table. This provides the ability to override
   * rates in the base table. Useful when only a subset of rates are different between tables.
   */
  base_rate_table_external_id?: string;
  base_rate_table_name?: string;
}

export const emptyRateTableItem: RateTableItem = {
  external_id: "",
  name: "",
  currency_code: "USD",
};

export type CreatableRateTable = { external_id: string } & EditableRateTable;

export interface EditableRateTable {
  name: string;
  description?: string;
  currency_code: string;
  base_rate_table_external_id?: string;
}

export type RateTableDataType = "rates" | "discounts" | "variables";

export interface RateTableApi {
  getAll: (page: number, itemsPerPage: number) => Promise<RateTableListResponse>;
  get: (rateTableId: string) => Promise<RateTableItem>;
  create: (newValue: CreatableRateTable) => Promise<void>;
  update: (rateTableId: string, newValue: EditableRateTable) => Promise<void>;
  remove: (rateTableId: string) => Promise<void>;
  getRateSets: (rateTableId: string) => Promise<RateSetItem[]>;
  associateRateSets: (rateTableId: string, rateSetIds: string[]) => Promise<void>;
  disassociateRateSet: (rateTableId: string, rateSetId: string) => Promise<void>;
  getChildren: (rateTableId: string) => Promise<RateTableItem[]>;
  uploadData: (rateTableId: string, dataType: RateTableDataType, data: unknown) => Promise<void>;
  downloadData: (rateTableId: string, dataType: RateTableDataType, version?: string) => Promise<Blob>;
}

const rateTable = (client: ApiFetch) => {
  const getAll = async (page = 0, itemsPerPage = 10): Promise<RateTableListResponse> => {
    const results = await client(`rate-tables?page=${page}&itemsPerPage=${itemsPerPage}`);

    await throwOnError(results);

    return await results.json();
  };

  const get = async (rateTableId: string): Promise<RateTableItem> => {
    const results = await client(joinUrlParts("rate-tables", rateTableId));
    return await results.json();
  };

  const create = async (newValue: CreatableRateTable): Promise<void> => {
    const results = await client("rate-tables", {
      method: "POST",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const update = async (rateTableId: string, newValue: EditableRateTable): Promise<void> => {
    const results = await client(joinUrlParts("rate-tables", rateTableId), {
      method: "PUT",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const remove = async (rateTableId: string): Promise<void> => {
    const results = await client(joinUrlParts("rate-tables", rateTableId), {
      method: "DELETE",
    });

    await throwOnError(results);
  };

  const getRateSets = async (rateTableId: string): Promise<RateSetItem[]> => {
    const results = await client(joinUrlParts("rate-tables", rateTableId, "rate-sets"));

    return await results.json();
  };

  const associateRateSets = async (rateTableId: string, rateSetIds: string[]): Promise<void> => {
    const results = await client(joinUrlParts("rate-tables", rateTableId, "rate-sets"), {
      method: "PATCH",
      body: JSON.stringify({ rate_sets: rateSetIds }),
    });

    await throwOnError(results);
  };

  const disassociateRateSet = async (rateTableId: string, rateSetId: string): Promise<void> => {
    const results = await client(joinUrlParts("rate-tables", rateTableId, "rate-sets", rateSetId), {
      method: "DELETE",
    });

    await throwOnError(results);
  };

  const getChildren = async (rateTableId: string): Promise<RateTableItem[]> => {
    const results = await client(joinUrlParts("rate-tables", rateTableId, "child-rate-tables"));

    await throwOnError(results);

    return await results.json();
  };

  const uploadData = async (rateTableId: string, dataType: RateTableDataType, data: unknown): Promise<void> => {
    const results = await client(joinUrlParts("rate-tables", rateTableId, dataType), {
      method: "POST",
      body: !data ? undefined : (data as BodyInit),
    });

    await throwOnError(results);
  };

  const downloadData = async (rateTableId: string, dataType: RateTableDataType, version?: string): Promise<Blob> => {
    const queryParam = !version ? "" : `?version=${version}`;
    const results = await client(joinUrlParts("rate-tables", rateTableId, `${dataType}${queryParam}`));

    await throwOnError(results);

    return results.blob();
  };

  return {
    getAll,
    get,
    create,
    update,
    remove,
    associateRateSets,
    disassociateRateSet,
    getRateSets,
    getChildren,
    uploadData,
    downloadData,
  };
};

export default rateTable;
