import { Box, Container, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Helmet } from "react-helmet-async";
import { ApiClient } from "../../api/client";
import { CodeResult } from "../../api/publishing";
import useApiClient from "../../api/useApiClient";
import CarrierContext from "../../components/CarrierContext";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import { performAsyncAction, useAsync } from "../../components/Loading/useAsync";
import { useLoadableState, useLoadingInfoState } from "../../components/Loading/useLoadableState";
import CodeUpload from "../../components/ratingLogic/CodeUpload";
import LogicEditor from "../../components/ratingLogic/LogicEditor";

const CodeUploadDetails = () => {
  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const [codeResult, setCodeResult] = useLoadableState<CodeResult>({
    code: "",
  });

  const decode = (input: string): string => (input ? atob(input) : "");
  const encode = (input: string): string => (input ? btoa(input) : "");

  const { carrier } = React.useContext(CarrierContext);

  const downLoadInfo = {
    filename: `${carrier?.name?.replace(/ /g, "")}_code.json`,
  };

  const onUploadCodeFile = (data?: BodyInit) => client.publishing.uploadCode(data);

  const onUploadRawCode = (rawCode: string) => client.publishing.uploadCode(`{ "code": "${encode(rawCode)}"}`);

  const onDownloadData = () => client.publishing.downloadCode();

  const getCodeFromServer = React.useCallback((apiClient: ApiClient) => apiClient.publishing.getCode(), []);

  useAsync(getCodeFromServer, setCodeResult);

  return (
    <>
      <Helmet>
        <title>Rating Logic</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Grid container>
          <Grid item xs={8}>
            <Container maxWidth={false} data-color-mode="dark">
              <Box>
                <Typography variant="h1">Carrier Rating Logic</Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                <LogicEditor
                  code={decode(codeResult?.data?.code)}
                  onUploadCode={(rawString) => {
                    performAsyncAction(
                      () => onUploadRawCode(rawString),
                      setLoadingInfo,
                      enqueueSnackbar,
                      () =>
                        client.publishing.getCode().then((x) =>
                          setCodeResult({
                            state: "finished",
                            data: { code: x.code },
                          })
                        )
                    );
                  }}
                />
              </Box>
            </Container>
          </Grid>
          <Grid item xs={4}>
            <Container maxWidth={false}>
              <Box>
                <Typography variant="h1">File Upload</Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                <CodeUpload
                  downloadInfo={downLoadInfo}
                  onDownloadData={onDownloadData}
                  onUploadCodeFile={(data) => {
                    performAsyncAction(
                      () => onUploadCodeFile(data),
                      setLoadingInfo,
                      enqueueSnackbar,
                      () =>
                        client.publishing.getCode().then((x) =>
                          setCodeResult({
                            state: "finished",
                            data: { code: x.code },
                          })
                        )
                    );
                  }}
                />
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default CodeUploadDetails;
