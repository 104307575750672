import React from "react";
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  IconButton,
  Checkbox,
  Typography,
  Icon,
} from "@mui/material";
import { Box } from "@mui/system";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DeleteRounded } from "@mui/icons-material";
import { Loadable } from "../Loading/Loadable";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import { RateSetItem } from "../../api/rateSet";
import { Link } from "react-router-dom";
import formatDate from "../../utils/formatDate";
import DialogBox from "../DialogBox";
import useApiClient from "../../api/useApiClient";

interface AssociatedRateSetListProps {
  rateSets: Loadable<{ external_id: string; name: string }[]>;
  onAssociateRateSets: (rateSetIds: string[]) => void;
  onDisassociateRateSet: (rateSetId: string) => void;
}

const AssociatedRateSetList = ({
  rateSets,
  onAssociateRateSets,
  onDisassociateRateSet,
}: AssociatedRateSetListProps) => {
  const client = useApiClient();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [allRateSets, setAllRateSets] = React.useState<RateSetItem[] | undefined>();
  const [selectedRateSetExternalIds, setSelectedRateSetExternalIds] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAssociateRateSetSelectOne = (_: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selectedRateSetExternalIds.indexOf(id);
    let newSelectedRateSetIds: string[] = [];

    if (selectedIndex === -1) {
      newSelectedRateSetIds = newSelectedRateSetIds.concat(selectedRateSetExternalIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRateSetIds = newSelectedRateSetIds.concat(selectedRateSetExternalIds.slice(1));
    } else if (selectedIndex === selectedRateSetExternalIds.length - 1) {
      newSelectedRateSetIds = newSelectedRateSetIds.concat(selectedRateSetExternalIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRateSetIds = newSelectedRateSetIds.concat(
        selectedRateSetExternalIds.slice(0, selectedIndex),
        selectedRateSetExternalIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRateSetExternalIds(newSelectedRateSetIds);
  };

  const handleAssociateRateSetSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRateSetExternalIds(
        allRateSets
          ?.filter((x) => !rateSets?.data?.find((y) => y.external_id === x.external_id))
          .map((rateSet) => rateSet.external_id) || []
      );
    } else {
      setSelectedRateSetExternalIds([]);
    }
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    const newRowsPerPage = parseInt(event?.target.value || "", 10);

    setRowsPerPage(isNaN(newRowsPerPage) ? 25 : newRowsPerPage);
    setPage(0);
  };

  const handlePageChange = (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  rateSets?.data?.sort((a, b) => (a.name > b.name ? 1 : -1));
  allRateSets?.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Rate Set
                    <IconButton
                      title="Associate rate sets"
                      color="success"
                      onClick={() => {
                        void client.rate.set.getAll().then((x) => setAllRateSets(x.rate_sets));
                        handleOpen();
                      }}
                    >
                      <Icon>add_circle</Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <LoadingWrapper loadingInfo={rateSets} loadingComponent={<LoadingTableRow colSpan={2} />}>
                  {rateSets?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((rateSet) => (
                    <TableRow hover key={rateSet.external_id}>
                      <TableCell>
                        <Link to={`/app/rateSet/${rateSet.external_id}`}>{rateSet.name}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="disassociate"
                          title="Remove rate set association"
                          color="error"
                          onClick={() => {
                            if (
                              window.confirm(`Are you sure you want to disassociate ${rateSet.external_id} rate set?`)
                            ) {
                              onDisassociateRateSet(rateSet.external_id);
                            }
                          }}
                        >
                          <DeleteRounded />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </LoadingWrapper>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={rateSets?.data?.length || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
        <DialogBox open={open} onClose={handleClose} title="Associate Rate Sets">
          Select the rate sets to associate.
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox onChange={handleAssociateRateSetSelectAll} />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Date Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allRateSets
                    ?.filter((x) => !rateSets?.data?.find((y) => y.external_id === x.external_id))
                    .map((rateSet) => (
                      <TableRow key={rateSet.external_id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedRateSetExternalIds.indexOf(rateSet.external_id) !== -1}
                            onChange={(event) => handleAssociateRateSetSelectOne(event, rateSet.external_id)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Typography color="textPrimary" variant="body1">
                              {rateSet.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{rateSet.description}</TableCell>
                        <TableCell>{formatDate(rateSet.created_date_utc)}</TableCell>
                        <TableCell>{formatDate(rateSet.last_modified_date_utc)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Button
                sx={{ m: 2 }}
                variant="contained"
                disabled={!selectedRateSetExternalIds?.length}
                onClick={() => {
                  onAssociateRateSets(selectedRateSetExternalIds);
                  setSelectedRateSetExternalIds([]);
                  handleClose();
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedRateSetExternalIds([]);
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </PerfectScrollbar>
        </DialogBox>
      </CardContent>
    </Card>
  );
};

export default AssociatedRateSetList;
