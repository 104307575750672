import { Box, Button, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import EditControls from "../EditControls";

interface ZoneFileDetailsToolbarProps {
  name: string;
  canDelete: boolean;
  editing: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

const ZoneFileDetailsToolbar = ({
  name,
  canDelete,
  editing,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  ...rest
}: ZoneFileDetailsToolbarProps) => (
  <Box {...rest}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h1">{name}</Typography>
      </Box>
      <Box>
        <Button component={RouterLink} to={"/app/zoneFiles"}>
          &lt;&lt; Back
        </Button>
        <EditControls
          editing={editing}
          canDelete={canDelete}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={onCancel}
          onDelete={onDelete}
        />
      </Box>
    </Box>
  </Box>
);

export default ZoneFileDetailsToolbar;
