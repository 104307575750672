import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Divider, Drawer, Hidden, List, Typography } from "@mui/material";
import {
  Activity as JobsIcon,
  CreditCard as RateCardIcon,
  Layers as RateSetIcon,
  List as RateTableIcon,
  Compass as ZoneSetIcon,
  FileText as ZoneFileIcon,
  Upload as PublishingIcon,
  Code as CodeUploadIcon,
  DollarSign as GetRateIcon,
} from "react-feather";
import NavItem from "./NavItem";
import CarrierSelection from "./carriers/CarrierSelection";
import UserDetails from "./UserDetails";

const sections = [
  {
    title: "Rating",
    items: [
      {
        href: "/app/rateCards",
        icon: RateCardIcon,
        title: "Rate Cards",
      },
      {
        href: "/app/rateSets",
        icon: RateSetIcon,
        title: "Rate Sets",
      },
      {
        href: "/app/rateTables",
        icon: RateTableIcon,
        title: "Rate Tables",
      },
      {
        href: "/app/code",
        icon: CodeUploadIcon,
        title: "Rating Logic",
      },
    ],
  },
  {
    title: "Zones",
    items: [
      {
        href: "/app/zoneSets",
        icon: ZoneSetIcon,
        title: "Zone Sets",
      },
      {
        href: "/app/zoneFiles",
        icon: ZoneFileIcon,
        title: "Zone Files",
      },
    ],
  },
  {
    title: "Publishing",
    items: [
      {
        href: "/app/publishing",
        icon: PublishingIcon,
        title: "Publish",
      },
      {
        href: "/app/jobs",
        icon: JobsIcon,
        title: "Check Job Status",
      },
      {
        href: "/app/getRates",
        icon: GetRateIcon,
        title: "Test Rates",
      },
    ],
  },
];

interface DashboardSidebarProps {
  onLocationChanged: () => void;
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar = ({
  onLocationChanged,
  onMobileClose,
  openMobile,
}: DashboardSidebarProps) => {
  const location = useLocation();
  React.useEffect(onLocationChanged, [location.pathname, onLocationChanged]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <UserDetails />
      </Box>
      <Divider sx={{ pb: 2 }} />

      <Box sx={{ p: 2 }} key="Carrier Selection Title">
        <CarrierSelection />
      </Box>

      {sections.map((section) => (
        <Box sx={{ p: 2 }} key={section.title}>
          <Typography color="textPrimary" variant="h5">
            {section.title}
          </Typography>
          <List>
            {section.items.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Box>
      ))}

      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
