import joinUrlParts from "../utils/joinUrlParts";
import { ApiFetch } from "./client";
import { ZoneFileItem } from "./zoneFile";
import { throwOnError } from "./throwOnError";

export interface ZoneSetListResponse {
  zone_sets: ZoneSetItem[];
  total_count: number;
}

export interface ZoneSetItem {
  external_id: string;
  name: string;
  description?: string;
  /** The UTC date-time the Zone set was created */
  created_date_utc?: string;
  /** The UTC date-time the Zone set was last modified. */
  last_modified_date_utc?: string;
  /** The UTC date-time the Zone set is effective from. */
  effective_date_utc: string;
  /** The UTC date-time the Zone set expires. */
  expiration_date_utc?: string;
}

export type CreatableZoneSet = { external_id: string } & EditableZoneSet;

export interface EditableZoneSet {
  name: string;
  description?: string;
  effective_date_utc: string;
  expiration_date_utc?: string;
}

export interface ZoneSetApi {
  getAll: (
    page?: number,
    itemsPerPage?: number
  ) => Promise<ZoneSetListResponse>;
  get: (zoneSetId: string) => Promise<ZoneSetItem>;
  create: (newValue: CreatableZoneSet) => Promise<void>;
  update: (zoneSetId: string, newValue: EditableZoneSet) => Promise<void>;
  remove: (zoneSetId: string) => Promise<void>;
  associateZoneFiles: (
    zoneSetId: string,
    zoneFileIds: string[]
  ) => Promise<void>;
  disassociateZoneFile: (
    zoneSetId: string,
    zoneFileId: string
  ) => Promise<void>;
  getZoneFiles: (zoneSetId: string) => Promise<ZoneFileItem[]>;
}

const zoneSet = (client: ApiFetch) => {
  const getAll = async (
    page = 0,
    itemsPerPage = 10
  ): Promise<ZoneSetListResponse> => {
    const results = await client(
      `zone-sets?page=${page}&itemsPerPage=${itemsPerPage}`
    );

    await throwOnError(results);

    return await results.json();
  };

  const get = async (zoneSetId: string): Promise<ZoneSetItem> => {
    const results = await client(joinUrlParts("zone-sets", zoneSetId));

    await throwOnError(results);

    return await results.json();
  };

  const create = async (newValue: CreatableZoneSet): Promise<void> => {
    const results = await client("zone-sets", {
      method: "POST",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const update = async (
    zoneSetId: string,
    newValue: EditableZoneSet
  ): Promise<void> => {
    const results = await client(joinUrlParts("zone-sets", zoneSetId), {
      method: "PUT",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const remove = async (zoneSetId: string): Promise<void> => {
    const results = await client(joinUrlParts("zone-sets", zoneSetId), {
      method: "DELETE",
    });

    await throwOnError(results);
  };

  const associateZoneFiles = async (
    zoneSetId: string,
    zoneFileIds: string[]
  ): Promise<void> => {
    const results = await client(
      joinUrlParts("zone-sets", zoneSetId, "zone-files"),
      {
        method: "PATCH",
        body: JSON.stringify({ zone_files: zoneFileIds }),
      }
    );

    await throwOnError(results);
  };

  const disassociateZoneFile = async (
    zoneSetId: string,
    zoneFileId: string
  ): Promise<void> => {
    const results = await client(
      joinUrlParts("zone-sets", zoneSetId, "zone-files", zoneFileId),
      {
        method: "DELETE",
      }
    );

    await throwOnError(results);
  };

  const getZoneFiles = async (zoneSetId: string): Promise<ZoneFileItem[]> => {
    const results = await client(
      joinUrlParts("zone-sets", zoneSetId, "zone-files")
    );

    await throwOnError(results);

    return await results.json();
  };

  return {
    getAll,
    get,
    create,
    update,
    remove,
    associateZoneFiles,
    disassociateZoneFile,
    getZoneFiles,
  };
};

export default zoneSet;
