import React from "react";
import { CarrierDetails } from "../api/carrier";

/** Empty carrier details */
export const emptyCarrierDetails: CarrierDetails = {
  id: "",
  name: "",
  apiCodes: [],
  description: "",
};

/** Contains the currently selected carrier */
const CarrierContext = React.createContext({
  /** Change the current carrier */
  change: (_: CarrierDetails) => {},
  /** Get the currently selected carrier */
  carrier: emptyCarrierDetails,
});

export default CarrierContext;
