import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import { Button, ListItem } from "@mui/material";

interface NavItemProps {
  href: string;
  icon: React.ElementType;
  title: string;
}

/** Does the current path match the given href, in singular or plural form
 * @param href The href of the link
 * @param currentPath The current path that the user is visiting
 * @returns True if the currentPath matches href or matches without the last character (which should be an 's'),
 * false otherwise
 */
const isSectionOrChild = (href: string, currentPath: string): boolean => {
  return (
    !!href &&
    (!!matchPath(
      {
        path: href,
        end: false,
      },
      currentPath
    ) ||
      !!matchPath(
        {
          path: href.slice(0, href.length - 1),
          end: false,
        },
        currentPath
      ))
  );
};

const NavItem = ({
  href,
  icon: Icon,
  title,
  ...rest
}: React.PropsWithoutRef<NavItemProps>) => {
  const location = useLocation();

  const active = isSectionOrChild(href, location.pathname);

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
      {...rest}
    >
      <Button
        component={RouterLink}
        sx={{
          color: "text.secondary",
          fontWeight: "medium",
          justifyContent: "flex-start",
          letterSpacing: 0,
          py: 1.25,
          textTransform: "none",
          width: "100%",
          ...(active && {
            color: "primary.main",
            fontWeight: "bold",
          }),
          "& svg": {
            mr: 1,
          },
        }}
        to={href}
      >
        <>
          {Icon && <Icon size="20" />}
          <span>{title}</span>
        </>
      </Button>
    </ListItem>
  );
};

export default NavItem;
