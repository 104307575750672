import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import getInitials from "../../utils/getInitials";
import { ZoneFileListResponse } from "../../api/zoneFile";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import { Loadable } from "../Loading/Loadable";

/** Props for the ZoneFileListResults component */
interface ZoneFileListResultsProps {
  /** List of zone files that will be displayed in the list */
  zoneFiles: Loadable<ZoneFileListResponse>;
  /** Function to execute when a zone file entry is clicked */
  onClick: (zoneFileId: string) => void;
}

const formatDate = (value?: string): string =>
  value ? new Date(value).toLocaleDateString() : "";

const ZoneFileListResults = ({
  zoneFiles,
  onClick,
  children,
  ...rest
}: React.PropsWithChildren<ZoneFileListResultsProps>) => {
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Date Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <LoadingWrapper
                loadingInfo={zoneFiles}
                loadingComponent={<LoadingTableRow colSpan={6} />}
              >
                {zoneFiles.data.zone_files.map((zoneFile) => (
                  <TableRow
                    hover
                    key={zoneFile.external_id}
                    onClick={() => onClick(zoneFile.external_id)}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Avatar src={""} sx={{ mr: 2 }}>
                          {getInitials(zoneFile.name)}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {zoneFile.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{zoneFile.description}</TableCell>
                    <TableCell>
                      {formatDate(zoneFile.created_date_utc)}
                    </TableCell>
                    <TableCell>
                      {formatDate(zoneFile.last_modified_date_utc)}
                    </TableCell>
                  </TableRow>
                ))}
              </LoadingWrapper>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {children}
    </Card>
  );
};

export default ZoneFileListResults;
