import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { CarrierDetails } from "../api/carrier";
import CarrierContext, { emptyCarrierDetails } from "./CarrierContext";
import JobContext from "./JobContext";
import JobManager from "./jobs/JobManager";
import { useLoadableState } from "./Loading/useLoadableState";
import { JobListResponse } from "../api/job";
//import { ZoneFiles } from "../pages/ZoneFiles/routes";
//import { RateCards } from "../pages/RateCards/routes";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 256,
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const reducer = (
  state: boolean,
  action: "changeLocation" | "open" | "close"
): boolean => {
  switch (action) {
    case "open":
      return true;
    case "close":
    case "changeLocation":
      return false;
    default:
      return state;
  }
};

// const routes = [
//   ...rateCardRoutes,
//   ...rateSetRoutes,
//   ...rateTableRoutes,
//   ...zoneSetRoutes,
//   ...zoneFileRoutes,
//   ...ratingLogicRoutes,
//   ...publishingRoutes,
//   ...jobRoutes,
//   ...getRatesRoutes,
//   { path: "dashboard", element: Dashboard },
//   { path: "carriers", element: CarrierList },
//   { path: "*", element: NotFound },
// ];

const DashboardLayout = () => {
  const [isMobileNavOpen, dispatch] = React.useReducer(reducer, false);
  const onLocationChangedCallback = React.useCallback(
    () => dispatch("changeLocation"),
    [dispatch]
  );
  const [carrier, setCarrier] = React.useState<CarrierDetails>(() => {
    const carrierData = localStorage.getItem("carrier");
    return carrierData
      ? (JSON.parse(carrierData) as CarrierDetails)
      : emptyCarrierDetails;
  });
  const [jobDetails, setJobDetails] = useLoadableState<JobListResponse>({
    jobs: [],
  });
  const [manualJobRefresh, setManualJobRefresh] = React.useState(0);

  return (
    <DashboardLayoutRoot>
      <CarrierContext.Provider
        value={{
          change: (carrier: CarrierDetails) => {
            localStorage.setItem("carrier", JSON.stringify(carrier));
            setCarrier(carrier);
          },
          carrier,
        }}
      >
        <JobContext.Provider
          value={{
            jobs: jobDetails.data.jobs,
            change: setJobDetails,
            refresh: () => setManualJobRefresh((x) => x + 1),
          }}
        >
          <JobManager refreshTrigger={manualJobRefresh} />
          <DashboardNavbar onMobileNavOpen={() => dispatch("open")} />
          <DashboardSidebar
            onMobileClose={() => dispatch("close")}
            onLocationChanged={onLocationChangedCallback}
            openMobile={isMobileNavOpen}
          />
          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent>
                <Outlet />
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </JobContext.Provider>
      </CarrierContext.Provider>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
