import { Box, Card, CardContent, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { RateSetItem } from "../../api/rateSet";
import { Loadable } from "../Loading/Loadable";
import LoadingBox from "../Loading/LoadingBox";
import LoadingWrapper from "../Loading/LoadingWrapper";

interface RateSetDisplayProps {
  rateSet: Loadable<RateSetItem>;
}

interface DetailItemProps {
  title: string;
  value?: string;
  valueVariant?: Variant;
}

const DetailItem = (props: DetailItemProps) => (
  <Box paddingBottom="1rem">
    <Typography color="textSecondary" variant="caption">
      {props.title}
    </Typography>
    <Typography color="textPrimary" variant={props.valueVariant || "body2"}>
      {props.value}
    </Typography>
  </Box>
);

const RateSetDisplay = ({ rateSet, ...rest }: RateSetDisplayProps) => {
  return (
    <Card {...rest}>
      <CardContent>
        <LoadingWrapper loadingInfo={rateSet} loadingComponent={<LoadingBox />}>
          <Box
            sx={{
              alignItems: "left",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailItem
              title="Name"
              value={rateSet.data.name}
              valueVariant="h3"
            />
            <DetailItem title="Description" value={rateSet.data.description} />
            <DetailItem
              title="Effective Date"
              value={rateSet.data.effective_date_utc}
            />
            <DetailItem
              title="Expiration Date"
              value={rateSet.data.expiration_date_utc}
            />
            <DetailItem title="Created" value={rateSet.data.created_date_utc} />
            <DetailItem
              title="Last Modified"
              value={rateSet.data.last_modified_date_utc}
            />
          </Box>
        </LoadingWrapper>
      </CardContent>
    </Card>
  );
};

export default RateSetDisplay;
