import React from "react";
import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CreatableRateTable, emptyRateTableItem } from "../../api/rateTable";
import RateTableDetailsToolbar from "../../components/rateTables/RateTableDetailsToolbar";
import RateTableCreate from "../../components/rateTables/RateTableCreate";
import { useLoadingInfoState } from "../../components/Loading/useLoadableState";
import { useSnackbar } from "notistack";
import { performAsyncAction } from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import useApiClient from "../../api/useApiClient";

const NewRateTable = () => {
  const navigate = useNavigate();
  const [creatableRateTable, setCreatableRateTable] =
    React.useState<CreatableRateTable>(emptyRateTableItem);

  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();

  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  return (
    <>
      <Helmet>
        <title>New Rate Table</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <RateTableDetailsToolbar
            name="New Rate Table"
            canDelete={false}
            editing={creatableRateTable !== undefined}
            onEdit={() => {
              // Do nothing
            }}
            onDelete={() => {
              // Do nothing
            }}
            onSave={() => {
              if (creatableRateTable) {
                performAsyncAction(
                  () => client.rate.table.create(creatableRateTable),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/rateTables")
                );
              }
            }}
            onCancel={() => navigate("/app/rateTables")}
          />
          <Box sx={{ pt: 3 }}>
            <RateTableCreate
              value={creatableRateTable}
              onChange={(x) => setCreatableRateTable(x)}
            />
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default NewRateTable;
