import joinUrlParts from "../utils/joinUrlParts";
import { ApiFetch } from "./client";
import { throwOnError } from "./throwOnError";

export interface PublishedVersionListResponse {
  publishes: PublishedVersion[];
}

export type PublishedVersion = VersionInfo & {
  rateCards: string[];
  zoneChart: string;
};

export interface VersionInfo {
  version: string;
  datePublished: string;
  transactionId: string;
}

export interface RateCardVersionListResponse {
  publishes: RateCardVersion[];
}

export type RateCardVersion = VersionInfo & {
  externalId: string;
  latest: boolean;
};

export interface CurrentVersion {
  zoneChart: VersionInfo;
  rateCards: RateCardVersion[];
}

const emptyVersionInfo: VersionInfo = {
  version: "",
  datePublished: "",
  transactionId: "",
};

export const emptyCurrentVersion: CurrentVersion = {
  zoneChart: emptyVersionInfo,
  rateCards: [],
};

export interface RateVersionDetail {
  carrier_app_id: string;
  external_id: string;
  currency_code: string;
  rate_table_id: string;
  base_rate_table_id: string;
  code: string;
  effective_date_utc: string;
  expiration_date_utc: string;
  rate_set_external_id: string;
  rate_set_order: string;
  version: string;
  adjustment_type: string;
  adjustment_value: string;
  versions: S3Version[];
}

export interface ZoneVersionDetail {
  carrier_app_id: string;
  external_id: string;
  zone_file_id: string;
  base_zone_file_id: string;
  effective_date_utc: string;
  expiration_date_utc: string;
  zone_set_external_id: string;
  zone_set_order: string;
  version: S3Version;
  changed_since_last_publish: boolean;
  code: string;
  s3Version: string;
}

export interface S3Version {
  type: string;
  s3Version: string;
  dataVersion: string;
  changed_since_last_publish: boolean;
  keyCount: number;
}

export interface PublishedRatingLogic {
  type: "carrier-default" | "rate-card-specific";
  codeVersion: string;
}

export type RateCardVersionDetail = RateCardVersion & {
  value: {
    rates: RateVersionDetail[];
    zones: ZoneVersionDetail[];
    ratingLogic: PublishedRatingLogic;
    codeVersion?: string;
  };
};

export interface RateCardVersionDetailResponse {
  details: RateCardVersionDetail;
}

const emptyRateCardVersionDetail: RateCardVersionDetail = {
  version: "",
  datePublished: "",
  transactionId: "",
  externalId: "",
  latest: false,
  value: {
    rates: [],
    zones: [],
    ratingLogic: {
      type: "carrier-default",
      codeVersion: "",
    },
  },
};

export const emptyRateCardVersionDetailResponse: RateCardVersionDetailResponse = {
  details: emptyRateCardVersionDetail,
};

export interface CodeResult {
  code: string;
}

export interface PublishingApi {
  getAll: () => Promise<PublishedVersionListResponse>;
  getCurrent: () => Promise<CurrentVersion>;
  publish: () => Promise<void>;
  revert: (versionId: string) => Promise<void>;
  uploadCode: (code?: BodyInit) => Promise<void>;
  downloadCode: (version?: string) => Promise<Blob>;
  getCode: () => Promise<CodeResult>;
}

const publishing = (client: ApiFetch) => {
  const getAll = async (): Promise<PublishedVersionListResponse> => {
    const results = await client(joinUrlParts("publish", "all"));
    return await results.json();
  };

  const getCurrent = async (): Promise<CurrentVersion> => {
    const results = await client(joinUrlParts("publish", "current"));
    return await results.json();
  };

  const publish = async (): Promise<void> => {
    const results = await client("publish", {
      method: "POST",
    });

    await throwOnError(results);
  };

  const revert = async (versionId: string): Promise<void> => {
    const results = await client(joinUrlParts("publish", versionId, "revert"), {
      method: "POST",
    });

    await throwOnError(results);
  };

  const uploadCode = async (code?: BodyInit): Promise<void> => {
    const results = await client("code", {
      method: "POST",
      body: code,
    });

    await throwOnError(results);
  };

  const getCode = async (): Promise<CodeResult> => {
    const results = await client("code", {
      method: "GET",
    });
    return await results.json();
  };

  const downloadCode = async (version?: string): Promise<Blob> => {
    const queryParam = !version ? "" : `?version=${version}`;
    const results = await client(`code${queryParam}`);

    await throwOnError(results);

    return results.blob();
  };

  return {
    getAll,
    getCurrent,
    publish,
    revert,
    uploadCode,
    getCode,
    downloadCode,
  };
};

export default publishing;
