import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useLoadableState } from "../../components/Loading/useLoadableState";
import { useAsync } from "../../components/Loading/useAsync";
import { ApiClient } from "../../api/client";
import { emptyRateCardVersionDetailResponse, RateCardVersionDetailResponse } from "../../api/publishing";
import RateCardVersionDisplay from "../../components/rateCards/RateCardVersionDisplay";
import RateCardVersionDetailToolbar from "../../components/rateCards/RateCardVersionDetailToolbar";
import RateCardVersionRatesResults from "../../components/rateCards/RateCardVersionRatesResults";
import RateCardVersionZonesResult from "../../components/rateCards/RateCardVersionZonesResult";
import { RateTableDataType } from "../../api/rateTable";
import useApiClient from "../../api/useApiClient";
import JSZip from "jszip";

const RateCardVersionDetails = () => {
  const client = useApiClient();
  const { id, versionId } = useParams<{ id: string; versionId: string }>() as {
    id: string;
    versionId: string;
  };

  const [rateCardVersionDetails, setRateCardVersionDetails] = useLoadableState<RateCardVersionDetailResponse>(
    emptyRateCardVersionDetailResponse
  );

  const getRateCardVersionDetailFromServer = React.useCallback(
    (client: ApiClient) => client.rate.card.getRateCardVersionDetail(id, versionId),
    [id, versionId]
  );

  const onDownloadRateData = (externalId: string, type: RateTableDataType, s3Version: string) =>
    client.rate.table.downloadData(externalId, type, s3Version);

  const onDownloadZoneData = (externalId: string, s3Version: string) =>
    client.zone.file.downloadData(externalId, s3Version);

  const codeVersion =
    rateCardVersionDetails?.data?.details?.value?.ratingLogic?.codeVersion ??
    rateCardVersionDetails?.data?.details?.value?.codeVersion;
  const codeType = rateCardVersionDetails?.data?.details?.value?.ratingLogic?.type ?? "carrier-default";
  const codeFilename = `${codeType}_code_${codeVersion}.json`;

  const onDownloadRatingLogic = () =>
    codeType === "rate-card-specific"
      ? client.rate.card.downloadCode(rateCardVersionDetails?.data?.details?.externalId, codeVersion)
      : client.publishing.downloadCode(codeVersion);

  const onDownloadAllData = () => {
    const zip = new JSZip();
    const ratesFolder = zip.folder("Rates");
    const zonesFolder = zip.folder("Zones");
    const logicFolder = zip.folder("Logic");

    rateCardVersionDetails?.data?.details?.value?.rates?.forEach((rate) => {
      rate.versions?.forEach((version) => {
        const dataBlob = onDownloadRateData(
          rate.external_id,
          `${version.type}s` as RateTableDataType,
          version.s3Version
        );
        const filename = `${rate.external_id}_${version.type}s_${version.s3Version}.json`;
        ratesFolder?.file(filename, dataBlob);
      });
    });

    rateCardVersionDetails?.data?.details?.value?.zones?.forEach((zone) => {
      const dataBlob = onDownloadZoneData(zone.external_id, zone.version?.s3Version);
      const filename = `${zone.external_id}_zones_${zone.version?.s3Version}.json`;
      zonesFolder?.file(filename, dataBlob);
    });

    const codeBlob = onDownloadRatingLogic();
    logicFolder?.file(codeFilename, codeBlob);

    void zip.generateAsync({ type: "blob" }).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${rateCardVersionDetails?.data?.details?.externalId}_${rateCardVersionDetails?.data?.details?.version}.zip`;
      a.click();
    });
  };

  useAsync(getRateCardVersionDetailFromServer, setRateCardVersionDetails);

  return (
    <>
      <Helmet>
        <title>Rate Card Version Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <RateCardVersionDetailToolbar
            onDownloadAllData={onDownloadAllData}
            name={`Rate Card Details: Version ${rateCardVersionDetails?.data?.details?.version}`}
            rateCardExternalId={id}
          />
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <RateCardVersionDisplay
                  rateCardVersionDetail={rateCardVersionDetails}
                  onDownloadData={onDownloadRatingLogic}
                  downloadFileName={codeFilename}
                  codeType={codeType}
                  codeVersion={codeVersion}
                />
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <Box>
                  <RateCardVersionRatesResults
                    rateCardVersionRates={rateCardVersionDetails?.data?.details?.value?.rates}
                    onDownloadData={onDownloadRateData}
                  />
                </Box>
                <Box sx={{ pt: 3 }}>
                  <RateCardVersionZonesResult
                    rateCardVersionZones={rateCardVersionDetails?.data?.details?.value?.zones}
                    onDownloadData={onDownloadZoneData}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default RateCardVersionDetails;
