import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { CarrierLogsResponse } from "../../api/carrier";
import { ApiClient } from "../../api/client";
import { RateCardItem } from "../../api/rateCard";
import useApiClient from "../../api/useApiClient";
import CarrierContext from "../../components/CarrierContext";
import LogViewer from "../../components/getRates/LogViewer";
import RateRequestEditor from "../../components/getRates/RateRequestEditor";
import RateResultViewer from "../../components/getRates/RateResultViewer";
import RequestHeaders from "../../components/getRates/RequestHeaders";
import { useAsync } from "../../components/Loading/useAsync";
import { useLoadableState } from "../../components/Loading/useLoadableState";

const GetRates = () => {
  const client = useApiClient();

  const [rateResult, setRateResult] = useState<any>();
  const [headers, setHeaders] = useState<HeadersInit>({});
  const [rateCards, setRateCards] = useState<RateCardItem[]>([]);
  const [carrierLogs, setCarrierLogs] = useLoadableState<CarrierLogsResponse>({
    logs: [],
  });

  const { carrier } = React.useContext(CarrierContext);

  const getRates = (rateRequest: unknown) => {
    let nativeRatingHeaders: Record<string, string> = {};

    Object.entries(headers).forEach(([key, value]) => {
      if (value && !(value === "false" || value === "published" || value === "")) {
        nativeRatingHeaders[key] = value;
      }
    });

    return client.rating.getRatesCapi(rateRequest, {
      "shipstation-carrierapicode": carrier.apiCodes[0],
      ...nativeRatingHeaders,
    });
  };

  const getCarrierLogs = React.useCallback((client: ApiClient) => client.carrier.getLogs(), []);

  useEffect(() => {
    client.rate.card
      .getAll(0, 500)
      .then((x) => setRateCards(x.rate_cards))
      .catch((err) => console.error(err));
  }, [client]);

  useAsync(getCarrierLogs, setCarrierLogs);

  const onDownloadLogs = () => client.carrier.downloadLogs();

  const downloadLogsInfo = {
    filename: `${carrier.id}_logs.json`,
    onDownload: onDownloadLogs,
  };

  return (
    <>
      <Helmet>
        <title>Get Rates</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Typography variant="h1">Rate Request</Typography>
          </Box>
          <Box sx={{ pt: 2 }}>
            <RequestHeaders rateCards={rateCards} onChange={(x) => setHeaders(x)} />
          </Box>
        </Container>
        <Grid container>
          <Grid item lg={6}>
            <Container maxWidth={false} data-color-mode="dark">
              <Box sx={{ pt: 2 }}>
                <RateRequestEditor
                  request={""}
                  onGetRates={(rateRequest) => getRates(rateRequest).then((x) => setRateResult(x))}
                />
              </Box>
            </Container>
          </Grid>
          <Grid item lg={6}>
            <Container maxWidth={false}>
              <Box sx={{ pt: 2 }}>
                <RateResultViewer result={rateResult} />
              </Box>
            </Container>
          </Grid>
        </Grid>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <LogViewer logs={carrierLogs} downloadInfo={downloadLogsInfo} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default GetRates;
