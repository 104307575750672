import { Card, CardContent, Grid, TextField } from "@mui/material";
import { EditableRateTable } from "../../api/rateTable";

interface RateTableEditProps {
  value: EditableRateTable;
  onChange: (newValue: EditableRateTable) => void;
}

const RateTableEdit = ({ value, onChange, ...rest }: RateTableEditProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form autoComplete="off" noValidate {...rest}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Name of the rate table"
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={value.name}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="If set, the rate table will inherit all the data from the specified rate table"
                label="Base Rate Table External ID"
                name="base_rate_table_external_id"
                onChange={handleChange}
                value={value.base_rate_table_external_id}
                variant="outlined"
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Currency of the rates in the table."
                label="Currency"
                name="currency_code"
                onChange={handleChange}
                required
                value={value.currency_code}
                variant="outlined"
                inputProps={{ maxLength: 3 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please describe the rate table"
                label="Description"
                name="description"
                onChange={handleChange}
                value={value.description}
                variant="outlined"
                multiline={true}
                rows={4}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RateTableEdit;
