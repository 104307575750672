import React, { SetStateAction } from "react";
import { JobItem, JobListResponse } from "../api/job";
import { Loadable } from "./Loading/Loadable";

export interface JobDetails {
  job_id: string;
  data_type: string;
  job_type: string;
  transaction_id: string;
  carrier_id: string;
  external_id: string;
  status: string;
  date_scheduled: string;
  date_started: string;
  date_completed: string;
  date_updated: string;
  version: string;
  total_items: number;
  synced_items: number;
  ms_since_last_update: number;
}

/** Contains the currently selected carrier */
const JobContext = React.createContext({
  /** Change the current carrier */
  change: (_: SetStateAction<Loadable<JobListResponse>>) => {},
  /** Refresh the jobs now instead of waiting for the timer to elapse */
  refresh: () => {},
  /** Get the currently selected carrier */
  jobs: [] as JobItem[],
});

export default JobContext;
