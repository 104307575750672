import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import { Loadable } from "../Loading/Loadable";
import { Link, useNavigate } from "react-router-dom";
import { DeleteRounded, AddCircle } from "@mui/icons-material";

/** Props for the RateCardCodeControls component */
interface RateCardCodeControlsProps {
  // Id of the rate card
  id: string;
  // Handle removing the logic
  onRemoveLogic: () => void;
  /** List of rate card versions that will be displayed */
  code: Loadable<string | undefined>;
}

const RateCardCodeControls = ({ id, onRemoveLogic, code }: React.PropsWithoutRef<RateCardCodeControlsProps>) => {
  const navigate = useNavigate();
  const codePath = `/app/rateCard/${id}/code`;

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <LoadingWrapper loadingInfo={code} loadingComponent={<LoadingTableRow colSpan={4} />}>
                  {code.data ? (
                    <TableRow hover>
                      <TableCell>
                        <Link to={codePath}>Rate card specific</Link>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="disassociate"
                          title="Remove rate set association"
                          color="error"
                          onClick={() => {
                            if (window.confirm(`Are you sure you want to remove the rate card specific logic?`)) {
                              onRemoveLogic();
                            }
                          }}
                        >
                          <DeleteRounded />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Link to={`/app/code`}>Carrier default</Link>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton title="Add rate card specific" color="success" onClick={() => navigate(codePath)}>
                          <AddCircle />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </LoadingWrapper>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default RateCardCodeControls;
