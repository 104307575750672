/** Get initials for a name
 * @param name Name for which to get initials
 * @returns Initials of the passed in name
 */
const getInitials = (name = ""): string =>
  name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");

export default getInitials;
