import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { EditableZoneFile, emptyZoneFileItem } from "../../api/zoneFile";
import ZoneFileDetailsToolbar from "../../components/zoneFiles/ZoneFileDetailsToolbar";
import ZoneFileDisplay from "../../components/zoneFiles/ZoneFileDisplay";
import ZoneFileEdit from "../../components/zoneFiles/ZoneFileEdit";
import { ZoneFileItem } from "../../api/zoneFile";
import {
  useLoadableState,
  useLoadingInfoState,
} from "../../components/Loading/useLoadableState";
import { useSnackbar } from "notistack";
import {
  performAsyncAction,
  useAsync,
} from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import AssociatedZoneSetList from "../../components/zoneSets/AssociatedZoneSetList";
import { ZoneSetItem } from "../../api/zoneSet";
import ZoneFileChildren from "../../components/zoneFiles/ZoneFileChildren";
import ZoneFileData from "../../components/zoneFiles/ZoneFileData";
import useApiClient from "../../api/useApiClient";
import { ApiClient } from "../../api/client";

const ZoneFileDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };
  const [zoneFile, setZoneFile] =
    useLoadableState<ZoneFileItem>(emptyZoneFileItem);
  const [zoneSets, setZoneSets] = useLoadableState<ZoneSetItem[]>([]);
  const [zoneFileChildren, setZoneFileChildren] = useLoadableState<
    ZoneFileItem[]
  >([]);
  const [editableZoneFile, setEditableZoneFile] = React.useState<
    EditableZoneFile | undefined
  >(undefined);
  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const getZoneFile = React.useCallback(
    (client: ApiClient) => client.zone.file.get(id),
    [id]
  );
  const getZoneSetsFromServer = React.useCallback(
    (client: ApiClient) => client.zone.file.getZoneSets(id),
    [id]
  );
  const getChildrenFromServer = React.useCallback(
    (client: ApiClient) => client.zone.file.getChildren(id),
    [id]
  );

  useAsync(getZoneFile, setZoneFile);
  useAsync(getZoneSetsFromServer, setZoneSets);
  useAsync(getChildrenFromServer, setZoneFileChildren);

  const downLoadInfo = {
    filename: `${zoneFile.data?.external_id}_zones.json`,
  };

  const onUploadData = zoneFile.data?.external_id
    ? (data: unknown) =>
        client.zone.file.uploadData(zoneFile.data.external_id, data)
    : () => Promise.resolve<void>(undefined);

  const onDownloadData = () =>
    client.zone.file.downloadData(zoneFile.data.external_id);

  return (
    <>
      <Helmet>
        <title>Zone File Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ZoneFileDetailsToolbar
            name={zoneFile.data.name || "Loading..."}
            canDelete={
              !!zoneFile && !!zoneSets && (zoneSets.data.length || 0) === 0
            }
            editing={editableZoneFile !== undefined}
            onEdit={() => {
              setEditableZoneFile(
                zoneFile && {
                  name: zoneFile.data.name || "",
                  description: zoneFile.data.description || "",
                  base_zone_file_external_id:
                    zoneFile.data.base_zone_file_external_id || "",
                }
              );
            }}
            onSave={() => {
              if (zoneFile && editableZoneFile) {
                performAsyncAction(
                  () => client.zone.file.update(id, editableZoneFile),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => {
                    performAsyncAction(
                      getZoneFile(client),
                      setZoneFile,
                      enqueueSnackbar
                    );
                    setEditableZoneFile(undefined);
                  }
                );
              }
            }}
            onCancel={() => {
              setEditableZoneFile(undefined);
            }}
            onDelete={() => {
              if (window.confirm("Are you sure you want to delete this?")) {
                performAsyncAction(
                  () => client.zone.file.remove(id),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/zoneFiles")
                );
              }
            }}
          />
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                {zoneFile &&
                  (editableZoneFile ? (
                    <ZoneFileEdit
                      value={editableZoneFile}
                      onChange={(x) => setEditableZoneFile(x)}
                    />
                  ) : (
                    <ZoneFileDisplay zoneFile={zoneFile} />
                  ))}
              </Grid>
              <Grid
                item
                container
                lg={8}
                md={6}
                xs={12}
                spacing={3}
                alignContent="flex-start"
              >
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <ZoneFileData
                    downloadInfo={downLoadInfo}
                    onUploadData={onUploadData}
                    onDownloadData={onDownloadData}
                  />
                </Grid>
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <AssociatedZoneSetList
                    zoneSets={zoneSets}
                    onAssociateZoneSets={(zoneSetIds) =>
                      client.zone.file
                        .associateZoneSets(id, zoneSetIds)
                        .then(() => {
                          performAsyncAction(
                            getZoneSetsFromServer(client),
                            setZoneSets,
                            enqueueSnackbar
                          );
                        })
                    }
                    onDisassociateZoneSet={(zoneSetId) =>
                      client.zone.file
                        .disassociateZoneSet(id, zoneSetId)
                        .then(() => {
                          performAsyncAction(
                            getZoneSetsFromServer(client),
                            setZoneSets,
                            enqueueSnackbar
                          );
                        })
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <ZoneFileChildren zoneFiles={zoneFileChildren} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default ZoneFileDetails;
