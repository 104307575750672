import { Box, Button, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

interface ListToolbarProps {
  /** Title of the toolbar */
  title: string;
  /** Title of the new item button */
  newButtonTitle: string;
  /** Url of the new item link */
  newUrl: string;
}

/** Toolbar for a list page
 * @param props Properties for the component
 */
const ListToolbar = (props: ListToolbarProps) => (
  <Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h1">{props.title}</Typography>
      </Box>
      <Box>
        <Button color="primary" sx={{ ml: 2 }} variant="contained" component={RouterLink} to={props.newUrl}>
          {props.newButtonTitle}
        </Button>
      </Box>
    </Box>
  </Box>
);

export default ListToolbar;
