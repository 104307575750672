import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import createClient from "./client";
import CarrierContext from "../components/CarrierContext";

const useApiClient = () => {
  const { carrier } = React.useContext(CarrierContext);
  const { authState } = useOktaAuth();

  const carrierId = carrier?.id;
  const idToken = authState?.idToken?.idToken;

  return React.useMemo(
    () => createClient(idToken || "", carrierId),
    [idToken, carrierId]
  );
};

export default useApiClient;
