import { Card, CardContent, Grid, TextField } from "@mui/material";
import { CreatableRateCard } from "../../api/rateCard";

interface RateCardCreateProps {
  value: CreatableRateCard;
  onChange: (newValue: CreatableRateCard) => void;
}

const RateCardCreate = ({ value, onChange, ...rest }: RateCardCreateProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form autoComplete="off" noValidate {...rest}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify an id of the rate card"
                label="External ID"
                name="external_id"
                onChange={handleChange}
                required
                value={value.external_id}
                variant="outlined"
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the name"
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={value.name}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please describe the rate card"
                label="Description"
                name="description"
                onChange={handleChange}
                value={value.description}
                variant="outlined"
                multiline={true}
                rows={4}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RateCardCreate;
