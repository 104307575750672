import { Box, Grid, Typography } from "@mui/material";
import { JobItem } from "../../api/job";
import { byString } from "../../utils/sortMethods";
import CompletedJobList from "./CompletedJobList";
import RunningJobList from "./RunningJobList";
import ScheduledJobList from "./ScheduledJobList";

interface JobListResultsProps {
  jobs: JobItem[];
  onClick: (jobId: string) => void;
}

const JobListResults = (props: JobListResultsProps) => {
  const runningJobs = props.jobs.filter((x) => x.status === "running");
  const scheduledJobs = props.jobs.filter((x) => x.status === "scheduled");
  const otherJobs = props.jobs.filter(
    (x) => x.status !== "running" && x.status !== "scheduled"
  );

  runningJobs.sort(byString((x) => x.date_started, { order: "asc" }));
  scheduledJobs.sort(byString((x) => x.date_scheduled, { order: "asc" }));
  otherJobs.sort(byString((x) => x.date_completed, { order: "desc" }));

  return (
    <>
      <Box>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <Box>
              <Typography color="textPrimary" variant="h2" sx={{ mb: 1 }}>
                Running
              </Typography>
              <RunningJobList jobs={runningJobs} />
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography color="textPrimary" variant="h2" sx={{ mb: 1 }}>
                Scheduled
              </Typography>
              <ScheduledJobList jobs={scheduledJobs} />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Typography color="textPrimary" variant="h2" sx={{ mb: 1 }}>
              Completed
            </Typography>
            <CompletedJobList jobs={otherJobs} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default JobListResults;
