import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { RateTableDataType } from "../../api/rateTable";
import DialogBox from "../DialogBox";
import { useSnackbar } from "notistack";
import JobContext from "../JobContext";

const defaultUploadData: { open: boolean; type: RateTableDataType } = {
  open: false,
  type: "rates",
};

interface DownloadInfo {
  filename: string;
}

interface FileTableRowProps {
  name: string;
  onUpload: () => void;
  onDownload: () => void;
  sampleDownloadLink: string;
}

const FileTableRow = ({
  name,
  onUpload,
  onDownload,
  sampleDownloadLink,
}: FileTableRowProps) => {
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell align="right">
        <ButtonGroup
          variant="text"
          color="primary"
          aria-label="text primary button group"
        >
          <Button onClick={onUpload}>Upload</Button>
          <Button onClick={onDownload}>Download</Button>
          <Button
            href={sampleDownloadLink}
            download
            sx={{
              borderLeft: "1px solid rgba(86, 100, 120, 0.5)",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Sample
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

interface RateTableFileListProps {
  getDownloadInfo: (type: RateTableDataType) => DownloadInfo;
  onUploadData: (type: RateTableDataType, data: unknown) => Promise<void>;
  onDownloadData: (type: RateTableDataType) => Promise<Blob>;
}

const RateTableFileList = ({
  getDownloadInfo,
  onUploadData,
  onDownloadData,
}: RateTableFileListProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadData, setUploadData] = React.useState(defaultUploadData);
  const [uploadButtonDisabled, setUploadButtonDisabled] = React.useState(true);
  const fileInput = React.useRef<HTMLInputElement>(null);
  const closeModal = () => setUploadData((x) => ({ ...x, open: false }));
  const jobContext = React.useContext(JobContext);

  const handleDownload = (type: RateTableDataType) => {
    void onDownloadData(type).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = getDownloadInfo(type).filename;
      a.click();
    });
  };

  const handleFileChange = (event: { target: { files: FileList | null } }) => {
    const file = event.target.files && event.target.files[0];
    !file ? setUploadButtonDisabled(true) : setUploadButtonDisabled(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Table size="small" width="100%">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FileTableRow
                name="Rates"
                onUpload={() => {
                  setUploadButtonDisabled(true);
                  setUploadData({ open: true, type: "rates" });
                }}
                onDownload={() => handleDownload("rates")}
                sampleDownloadLink="/sampleData/sample_rates.json"
              />
              <FileTableRow
                name="Discounts"
                onUpload={() => {
                  setUploadButtonDisabled(true);
                  setUploadData({ open: true, type: "discounts" });
                }}
                onDownload={() => handleDownload("discounts")}
                sampleDownloadLink="/sampleData/sample_discounts.json"
              />
              <FileTableRow
                name="Variables"
                onUpload={() => {
                  setUploadButtonDisabled(true);
                  setUploadData({ open: true, type: "variables" });
                }}
                onDownload={() => handleDownload("variables")}
                sampleDownloadLink="/sampleData/sample_variables.json"
              />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <DialogBox
        open={uploadData.open}
        onClose={closeModal}
        title="Upload file"
        description="Select the file to upload"
      >
        <input type="file" ref={fileInput} onChange={handleFileChange} />
        <Button
          sx={{ m: 2 }}
          variant="contained"
          disabled={uploadButtonDisabled}
          onClick={() => {
            const file = fileInput.current?.files?.[0];
            if (file === undefined) {
              return;
            }

            file
              .text()
              .then((text) => {
                onUploadData(uploadData.type, text).catch((err) =>
                  console.error(err)
                );
              })
              .then(() => jobContext.refresh())
              .catch((err) =>
                enqueueSnackbar(err.message, { variant: "error" })
              )
              .finally(closeModal);
          }}
        >
          Upload
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
      </DialogBox>
    </>
  );
};

export default RateTableFileList;
