import React from "react";
import { useSnackbar } from "notistack";
import useApiClient from "../../api/useApiClient";
import CarrierContext from "../CarrierContext";
import JobContext from "../JobContext";
import { performAsyncAction } from "../Loading/useAsync";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";

const defaultDelay = minutesToMilliseconds(1);
const fastDelay = secondsToMilliseconds(3);

interface JobManagerProps {
  refreshTrigger: number;
}

const JobManager = (props: JobManagerProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();
  const carrierContext = React.useContext(CarrierContext);
  const jobContext = React.useContext(JobContext);

  React.useEffect(() => {
    let fastUpdateCoolDown = 0;
    let timeoutId: NodeJS.Timeout;
    let asyncAction = () => {
      // Do nothing
    };

    let scheduleNext = (fastUpdate: boolean) => {
      if (fastUpdate) {
        fastUpdateCoolDown = 10;
      } else if (fastUpdateCoolDown > 0) {
        fastUpdateCoolDown -= 1;
      }

      const delay =
        fastUpdate || fastUpdateCoolDown > 0 ? fastDelay : defaultDelay;
      console.log(
        `Scheduling next job fetch for ${delay}ms (cooldown: ${fastUpdateCoolDown})`
      );

      timeoutId = setTimeout(refresh, delay);
    };

    const refresh = () => {
      console.log("Fetching jobs");
      let fastUpdate = false;
      asyncAction = performAsyncAction(
        () =>
          client.job
            .getAll()
            .then((value) => {
              fastUpdate = value.jobs.some(
                (job) => job.status === "running" || job.status === "scheduled"
              );
              return value;
            })
            .finally(() => scheduleNext(fastUpdate)),
        jobContext.change,
        enqueueSnackbar,
        undefined
      );
    };

    refresh();

    return () => {
      scheduleNext = () => {
        console.log(
          "Not scheduling next job refresh because component was unloaded"
        );
        // Do nothing
      };
      asyncAction();

      if (timeoutId) {
        console.log("Cancelling scheduled job fetch");
        clearTimeout(timeoutId);
      }
    };
  }, [
    carrierContext.carrier.id,
    client.job,
    enqueueSnackbar,
    jobContext.change,
    props.refreshTrigger,
  ]);

  return <></>;
};

export default JobManager;
