import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { EditableRateTable, emptyRateTableItem, RateTableDataType } from "../../api/rateTable";
import RateTableDetailsToolbar from "../../components/rateTables/RateTableDetailsToolbar";
import RateTableDisplay from "../../components/rateTables/RateTableDisplay";
import RateTableEdit from "../../components/rateTables/RateTableEdit";
import { RateTableItem } from "../../api/rateTable";
import { useLoadableState, useLoadingInfoState } from "../../components/Loading/useLoadableState";
import { useSnackbar } from "notistack";
import { performAsyncAction, useAsync } from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import AssociatedRateSetList from "../../components/rateSets/AssociatedRateSetList";
import { RateSetItem } from "../../api/rateSet";
import RateTableChildren from "../../components/rateTables/RateTableChildren";
import RateTableFileList from "../../components/rateTables/RateTableFileList";
import useApiClient from "../../api/useApiClient";
import { ApiClient } from "../../api/client";

const RateTableDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };
  const [rateTable, setRateTable] = useLoadableState<RateTableItem>(emptyRateTableItem);
  const [rateSets, setRateSets] = useLoadableState<RateSetItem[]>([]);
  const [rateTableChildren, setRateTableChildren] = useLoadableState<RateTableItem[]>([]);
  const [editableRateTable, setEditableRateTable] = React.useState<EditableRateTable | undefined>(undefined);
  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const getRateTable = React.useCallback((client: ApiClient) => client.rate.table.get(id), [id]);
  const getRateSetsFromServer = React.useCallback((client: ApiClient) => client.rate.table.getRateSets(id), [id]);
  const getChildrenFromServer = React.useCallback((client: ApiClient) => client.rate.table.getChildren(id), [id]);

  useAsync(getRateTable, setRateTable);
  useAsync(getRateSetsFromServer, setRateSets);
  useAsync(getChildrenFromServer, setRateTableChildren);

  const getDownloadInfo = rateTable.data?.external_id
    ? (x: RateTableDataType) => ({
        filename: `${rateTable.data.external_id}_${x}.json`,
      })
    : () => ({ filename: "" });

  const onUploadData = rateTable.data?.external_id
    ? (type: RateTableDataType, data: unknown) => client.rate.table.uploadData(rateTable.data.external_id, type, data)
    : () => Promise.resolve<void>(undefined);

  const onDownloadData = (type: RateTableDataType) => client.rate.table.downloadData(rateTable.data.external_id, type);

  return (
    <>
      <Helmet>
        <title>Rate Card Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <RateTableDetailsToolbar
            name={rateTable.data.name || "Loading..."}
            canDelete={!!rateTable && !!rateSets && (rateSets.data.length || 0) === 0}
            editing={editableRateTable !== undefined}
            onEdit={() => {
              setEditableRateTable(
                rateTable && {
                  name: rateTable.data.name || "",
                  description: rateTable.data.description || "",
                  currency_code: rateTable.data.currency_code || "",
                  base_rate_table_external_id: rateTable.data.base_rate_table_external_id || "",
                }
              );
            }}
            onSave={() => {
              if (rateTable && editableRateTable) {
                performAsyncAction(
                  () => client.rate.table.update(id, editableRateTable),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => {
                    performAsyncAction(getRateTable(client), setRateTable, enqueueSnackbar);
                    setEditableRateTable(undefined);
                  }
                );
              }
            }}
            onCancel={() => {
              setEditableRateTable(undefined);
            }}
            onDelete={() => {
              if (window.confirm("Are you sure you want to delete this?")) {
                performAsyncAction(
                  () => client.rate.table.remove(id),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/rateTables")
                );
              }
            }}
          />
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                {rateTable &&
                  (editableRateTable ? (
                    <RateTableEdit value={editableRateTable} onChange={(x) => setEditableRateTable(x)} />
                  ) : (
                    <RateTableDisplay rateTable={rateTable} />
                  ))}
              </Grid>
              <Grid item container lg={8} md={6} xs={12} spacing={3} alignContent="flex-start">
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <RateTableFileList
                    getDownloadInfo={getDownloadInfo}
                    onUploadData={onUploadData}
                    onDownloadData={onDownloadData}
                  />
                </Grid>
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <AssociatedRateSetList
                    rateSets={rateSets}
                    onAssociateRateSets={(rateSetIds) =>
                      performAsyncAction(
                        () => client.rate.table.associateRateSets(id, rateSetIds),
                        setLoadingInfo,
                        enqueueSnackbar,
                        () => performAsyncAction(getRateSetsFromServer(client), setRateSets, enqueueSnackbar)
                      )
                    }
                    onDisassociateRateSet={(rateSetId) =>
                      performAsyncAction(
                        () => client.rate.table.disassociateRateSet(id, rateSetId),
                        setLoadingInfo,
                        enqueueSnackbar,
                        () => performAsyncAction(getRateSetsFromServer(client), setRateSets, enqueueSnackbar)
                      )
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <RateTableChildren rateTables={rateTableChildren} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default RateTableDetails;
