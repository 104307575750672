import React from "react";
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from "notistack";
import { Loadable } from "./Loadable";
import CarrierNotSelectedError from "../../api/CarrierNotSelectedError";
import SelectCarrierAction from "./SelectCarrierAction";
import useApiClient from "../../api/useApiClient";
import { ApiClient } from "../../api/client";

/** Function performs an async action
 * @param asyncFn Function that will be performed when dependencies change
 * @param updateState Function that will be used to update the relevant loadable state
 * @param enqueueSnackbar Function to enqueue a snackbar item on failure
 * @param onSuccess Function that will be executed if the async function finishes successfully
 */
export function performAsyncAction<T = void>(
  asyncFn: (() => Promise<T>) | Promise<T>,
  updateState: React.Dispatch<React.SetStateAction<Loadable<T>>>,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey,
  onSuccess?: () => void
) {
  let isActive = true;
  updateState((x) => ({ ...x, state: "loading" }));

  const promise = typeof asyncFn === "function" ? asyncFn() : asyncFn;
  promise.then(
    (data) => {
      if (isActive) {
        updateState((x) => ({ ...x, state: "finished", data }));
        if (onSuccess) onSuccess();
      }
    },
    (error) => {
      if (isActive) updateState((x) => ({ ...x, state: "error", error }));
      console.error(error);

      if (error instanceof CarrierNotSelectedError) {
        enqueueSnackbar("No carrier selected", {
          variant: "warning",
          action: SelectCarrierAction,
        });
      } else {
        enqueueSnackbar(`Action failed: ${error.message}`, {
          variant: "error",
        });
      }
    }
  );
  return () => {
    isActive = false;
  };
}

/** Hook that will perform an async action
 * @param asyncFn Function that will be performed when dependencies change
 * @param updateState Function that will be used to update the relevant loadable state
 * @param onSuccess Function that will be executed if the async function finishes successfully
 */
export function useAsync<T = void>(
  asyncFn: (client: ApiClient) => Promise<T>,
  updateState: React.Dispatch<React.SetStateAction<Loadable<T>>>,
  onSuccess?: () => void
) {
  const client = useApiClient();
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(
    () =>
      performAsyncAction(
        () => asyncFn(client),
        updateState,
        enqueueSnackbar,
        onSuccess
      ),
    [client, asyncFn, updateState, onSuccess, enqueueSnackbar]
  );
}
