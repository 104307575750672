import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { PublishedVersionListResponse } from "../../api/publishing";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import { Loadable } from "../Loading/Loadable";
import { formatDateTime } from "../../utils/formatDate";
import { Link } from "react-router-dom";

/** Props for the PublishedVersionListResults component */
interface PublishedVersionListResultsProps {
  /** List of published versions that will be displayed in the list */
  versions: Loadable<PublishedVersionListResponse>;
}

const PublishedVersionListResults = ({
  versions,
}: React.PropsWithoutRef<PublishedVersionListResultsProps>) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Version</TableCell>
                <TableCell>Zone Version</TableCell>
                <TableCell>Published</TableCell>
                <TableCell>Rate Cards</TableCell>
                <TableCell>Transaction Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <LoadingWrapper
                loadingInfo={versions}
                loadingComponent={<LoadingTableRow colSpan={4} />}
              >
                {versions?.data?.publishes
                  ?.map((version) => (
                    <TableRow hover key={version.version}>
                      <TableCell>{version.version}</TableCell>
                      <TableCell>{version.zoneChart}</TableCell>
                      <TableCell>
                        {formatDateTime(version.datePublished)}
                      </TableCell>
                      <TableCell>
                        {version.rateCards?.map((externalId, i) => (
                          <Link
                            to={`/app/rateCard/${externalId}`}
                            style={{ paddingRight: 10 }}
                            key={i}
                          >
                            {externalId}
                          </Link>
                        ))}
                      </TableCell>

                      <TableCell>{version.transactionId}</TableCell>
                    </TableRow>
                  ))
                  .slice(0, 20)}
              </LoadingWrapper>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default PublishedVersionListResults;
