import joinUrlParts from "../utils/joinUrlParts";
import { ApiFetch } from "./client";
import { throwOnError } from "./throwOnError";

export interface JobItem {
  job_id: string;
  transaction_id: string;
  carrier_id: string;
  external_id: string;
  job_type: string;
  data_type: string;
  status: string;
  date_scheduled: string;
  date_started: string;
  date_completed: string;
  date_updated: string;
  version: string;
  failure_reason: string;
  total_items: number;
  synced_items: number;
  ms_since_last_update: number;
}

export interface JobListResponse {
  jobs: JobItem[];
}

export interface JobResponse {
  job: JobItem | undefined;
}

export interface JobApi {
  getAll: () => Promise<JobListResponse>;
  get: (jobId: string) => Promise<JobResponse>;
}

const job = (basicClient: ApiFetch, carrierClient: ApiFetch): JobApi => {
  const getAll = async (): Promise<JobListResponse> => {
    const results = await carrierClient(`jobs`);

    await throwOnError(results);

    return await results.json();
  };

  const get = async (jobId: string): Promise<JobResponse> => {
    const results = await basicClient(joinUrlParts("job", jobId));

    await throwOnError(results);

    return await results.json();
  };

  return {
    getAll,
    get,
  };
};

export default job;
