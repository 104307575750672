import { LoadingInfo } from "./Loadable";

/** Props for the LoadingWrapper component */
interface LoadingWrapperProps {
  /** Is the application currently loading data */
  loadingInfo: LoadingInfo;
  loadingComponent: JSX.Element;
}

/** Component that disables the screen while data is loading */
const LoadingWrapper = ({
  loadingInfo,
  loadingComponent,
  children,
}: React.PropsWithChildren<LoadingWrapperProps>) => {
  console.log("Loading state", loadingInfo.state);

  return (
    <>
      {loadingInfo.state === "loading" ? (
        loadingComponent
      ) : loadingInfo.state === "error" ? (
        <div>Error</div>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingWrapper;
