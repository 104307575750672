import CodeUploadDetails from "./CodeUploadDetails";

const ratingLogicRoutes = [
  {
    path: "code",
    exact: true,
    element: CodeUploadDetails,
  },
];

export default ratingLogicRoutes;
