import { Box, Button, Typography } from "@mui/material";

interface PublishingToolbarProps {
  name: string;
  onPublish: () => void;
}

const PublishingToolbar = ({
  name,
  onPublish,
  ...rest
}: React.PropsWithoutRef<PublishingToolbarProps>) => (
  <Box {...rest}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h1">{name}</Typography>
      </Box>
      <Box>
        <Button
          title="Publish Carrier"
          sx={{ ml: 2 }}
          color="primary"
          variant="contained"
          onClick={onPublish}
        >
          Publish
        </Button>
      </Box>
    </Box>
  </Box>
);

export default PublishingToolbar;
