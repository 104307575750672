import React from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { RateCardItem } from "../../api/rateCard";
import { Loadable } from "../Loading/Loadable";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";

interface RateSetRateCardListProps {
  rateCards: Loadable<RateCardItem[]>;
}

const RateSetRateCardList = ({ rateCards }: RateSetRateCardListProps) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => {
    const newRowsPerPage = parseInt(event?.target.value || "", 10);

    setRowsPerPage(isNaN(newRowsPerPage) ? 25 : newRowsPerPage);
    setPage(0);
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Rate Cards</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <LoadingWrapper
                  loadingInfo={rateCards}
                  loadingComponent={<LoadingTableRow colSpan={2} />}
                >
                  {rateCards.data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((rateCard) => (
                      <TableRow hover key={rateCard.external_id}>
                        <TableCell>
                          <Link to={`/app/rateCard/${rateCard.external_id}`}>
                            {rateCard.name}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </LoadingWrapper>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={Number(rateCards.data?.length) || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardContent>
    </Card>
  );
};

export default RateSetRateCardList;
