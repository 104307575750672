import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { EditableZoneSet } from "../../api/zoneSet";
import ZoneSetDetailsToolbar from "../../components/zoneSets/ZoneSetDetailsToolbar";
import ZoneSetDisplay from "../../components/zoneSets/ZoneSetDisplay";
import ZoneSetEdit from "../../components/zoneSets/ZoneSetEdit";
import { ZoneSetItem } from "../../api/zoneSet";
import ZoneSetZoneFileList from "../../components/zoneSets/ZoneSetZoneFileList";
import { ZoneFileItem } from "../../api/zoneFile";
import {
  useLoadableState,
  useLoadingInfoState,
} from "../../components/Loading/useLoadableState";
import { useSnackbar } from "notistack";
import {
  performAsyncAction,
  useAsync,
} from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import useApiClient from "../../api/useApiClient";
import { ApiClient } from "../../api/client";

const ZoneSetDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };
  const [zoneSet, setZoneSet] = useLoadableState<ZoneSetItem>({
    external_id: "",
    name: "",
    effective_date_utc: "",
    expiration_date_utc: "",
  });
  const [zoneFiles, setZoneFiles] = useLoadableState<ZoneFileItem[]>([]);
  const [editableZoneSet, setEditableZoneSet] = React.useState<
    EditableZoneSet | undefined
  >(undefined);
  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const getZoneSet = React.useCallback(
    (client: ApiClient) => client.zone.set.get(id),
    [id]
  );
  const getZoneFilesForZoneSet = React.useCallback(
    (client: ApiClient) => client.zone.set.getZoneFiles(id),
    [id]
  );

  useAsync(getZoneSet, setZoneSet);
  useAsync(getZoneFilesForZoneSet, setZoneFiles);

  return (
    <>
      <Helmet>
        <title>Rate Set Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ZoneSetDetailsToolbar
            name={zoneSet?.data.name || "Loading..."}
            canDelete={
              !!zoneSet && !!zoneFiles && (zoneFiles.data.length || 0) === 0
            }
            editing={editableZoneSet !== undefined}
            onEdit={() => {
              setEditableZoneSet(
                zoneSet && {
                  name: zoneSet.data.name,
                  description: zoneSet.data.description || undefined,
                  effective_date_utc: zoneSet.data.effective_date_utc,
                  expiration_date_utc:
                    zoneSet.data.expiration_date_utc || undefined,
                }
              );
            }}
            onSave={() => {
              if (zoneSet && editableZoneSet) {
                performAsyncAction(
                  () => client.zone.set.update(id, editableZoneSet),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => {
                    setZoneSet({
                      ...zoneSet,
                      data: {
                        ...zoneSet.data,
                        ...editableZoneSet,
                      },
                    });
                    setEditableZoneSet(undefined);
                  }
                );
              }
            }}
            onCancel={() => {
              setEditableZoneSet(undefined);
            }}
            onDelete={() => {
              if (window.confirm("Are you sure you want to delete this?")) {
                performAsyncAction(
                  () => client.zone.set.remove(id),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/zoneSets")
                );
              }
            }}
          />
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                {zoneSet &&
                  (editableZoneSet ? (
                    <ZoneSetEdit
                      value={editableZoneSet}
                      onChange={(x) => setEditableZoneSet(x)}
                    />
                  ) : (
                    <ZoneSetDisplay zoneSet={zoneSet} />
                  ))}
              </Grid>

              <Grid item lg={8} md={6} xs={12}>
                <ZoneSetZoneFileList
                  zoneFiles={zoneFiles}
                  onAssociateZoneFiles={(zoneFileIds) =>
                    client.zone.set
                      .associateZoneFiles(id, zoneFileIds)
                      .then(() => {
                        performAsyncAction(
                          getZoneFilesForZoneSet(client),
                          setZoneFiles,
                          enqueueSnackbar
                        );
                      })
                  }
                  onDisassociateZoneFile={(zoneFileId) =>
                    client.zone.set
                      .disassociateZoneFile(id, zoneFileId)
                      .then(() => {
                        performAsyncAction(
                          getZoneFilesForZoneSet(client),
                          setZoneFiles,
                          enqueueSnackbar
                        );
                      })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default ZoneSetDetails;
