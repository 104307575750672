import { Box, Button, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

interface RateCardDetailsToolbarProps {
  name: string;
  canDelete: boolean;
  editing: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

const RateCardDetailsToolbar = ({
  name,
  canDelete,
  editing,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  ...rest
}: React.PropsWithoutRef<RateCardDetailsToolbarProps>) => (
  <Box {...rest}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h1">{name}</Typography>
      </Box>
      <Box>
        <Button component={RouterLink} to={"/app/rateCards"}>
          &lt;&lt; Back
        </Button>
        {editing ? (
          <>
            <Button
              sx={{ ml: 2 }}
              color="secondary"
              variant="contained"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              sx={{ ml: 2 }}
              color="primary"
              variant="contained"
              onClick={onSave}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            {!editing && canDelete && (
              <Button
                sx={{ ml: 2 }}
                color="error"
                variant="contained"
                onClick={onDelete}
              >
                Delete
              </Button>
            )}
            <Button
              sx={{ ml: 2 }}
              color="primary"
              variant="contained"
              onClick={onEdit}
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    </Box>
  </Box>
);

export default RateCardDetailsToolbar;
