import React from "react";
import { Loadable } from "./Loadable";

export function useLoadingInfoState() {
  return React.useState<Loadable<void>>({ state: "idle", data: undefined });
}

export function useLoadableState<T>(defaultValue: T) {
  return React.useState<Loadable<T>>({
    state: "idle",
    data: defaultValue,
  });
}
