import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import JobListResults from "../../components/jobs/JobListResults";
import JobContext from "../../components/JobContext";

const JobList = (props: React.PropsWithoutRef<{}>) => {
  const jobContext = React.useContext(JobContext);

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Job List</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box {...props}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h1">Jobs</Typography>
              </Box>
              <Box>
                <Button
                  color="primary"
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={jobContext.refresh}
                >
                  Refresh
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <JobListResults
              jobs={jobContext.jobs}
              onClick={(x) => navigate(`/app/job/${x}`)}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default JobList;
