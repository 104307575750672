import { Box, Button, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

interface RateCardVersionDetailToolbarProps {
  name: string;
  rateCardExternalId: string;
  onDownloadAllData: () => void;
}

const RateCardVersionDetailToolbar = ({
  name,
  rateCardExternalId,
  onDownloadAllData,
  ...rest
}: React.PropsWithoutRef<RateCardVersionDetailToolbarProps>) => (
  <Box {...rest}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h1">{name}</Typography>
      </Box>
      <Box>
        <Button component={RouterLink} title={`back to rate card`} to={`/app/rateCard/${rateCardExternalId}`}>
          &lt;&lt; Back
        </Button>
        <Button
          title="Download All Data"
          onClick={onDownloadAllData}
          sx={{ ml: 2 }}
          color="primary"
          variant="contained"
        >
          Download All Data
        </Button>
      </Box>
    </Box>
  </Box>
);

export default RateCardVersionDetailToolbar;
