import React from "react";
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Button,
  Typography,
  Icon,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DeleteRounded } from "@mui/icons-material";
import { RateTableItem } from "../../api/rateTable";
import formatDate from "../../utils/formatDate";
import { Loadable } from "../Loading/Loadable";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import DialogBox from "../DialogBox";
import useApiClient from "../../api/useApiClient";

interface RateSetRateTableListProps {
  rateTables: Loadable<{ external_id: string; name: string }[]>;
  onAssociateRateTables: (rateTableIds: string[]) => void;
  onDisassociateRateTable: (rateTableId: string) => void;
}

const RateSetRateTableList = ({
  rateTables,
  onAssociateRateTables,
  onDisassociateRateTable,
}: RateSetRateTableListProps) => {
  const client = useApiClient();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [allRateTables, setAllRateTables] = React.useState<RateTableItem[] | undefined>();
  const [selectedRateTableExternalIds, setSelectedRateTableExternalIds] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAssociateRateTableSelectOne = (_: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selectedRateTableExternalIds.indexOf(id);
    let newSelectedRateTableIds: string[] = [];

    if (selectedIndex === -1) {
      newSelectedRateTableIds = newSelectedRateTableIds.concat(selectedRateTableExternalIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRateTableIds = newSelectedRateTableIds.concat(selectedRateTableExternalIds.slice(1));
    } else if (selectedIndex === selectedRateTableExternalIds.length - 1) {
      newSelectedRateTableIds = newSelectedRateTableIds.concat(selectedRateTableExternalIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRateTableIds = newSelectedRateTableIds.concat(
        selectedRateTableExternalIds.slice(0, selectedIndex),
        selectedRateTableExternalIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRateTableExternalIds(newSelectedRateTableIds);
  };

  const handleAssociateRateTableSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRateTableExternalIds(
        allRateTables
          ?.filter((x) => !rateTables?.data?.find((y) => y.external_id === x.external_id))
          .map((rateTable) => rateTable.external_id) || []
      );
    } else {
      setSelectedRateTableExternalIds([]);
    }
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    const newPageLimit = parseInt(event?.target.value || "", 10);

    setRowsPerPage(isNaN(newPageLimit) ? 25 : newPageLimit);
    setPage(0);
  };

  const handlePageChange = (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  rateTables?.data?.sort((a, b) => (a.name > b.name ? 1 : -1));
  allRateTables?.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Rate Tables
                    <IconButton
                      title="Associate rate tables"
                      color="success"
                      onClick={() => {
                        void client.rate.table.getAll().then((x) => setAllRateTables(x.rate_tables));
                        handleOpen();
                      }}
                    >
                      <Icon>add_circle</Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <LoadingWrapper loadingInfo={rateTables} loadingComponent={<LoadingTableRow colSpan={2} />}>
                  {rateTables?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((rateTable) => (
                    <TableRow hover key={rateTable.external_id}>
                      <TableCell>
                        <Link to={`/app/rateTable/${rateTable.external_id}`}>{rateTable.name}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="disassociate"
                          title="Remove rate table association"
                          color="error"
                          onClick={() => {
                            if (
                              window.confirm(
                                `Are you sure you want to disassociate ${rateTable.external_id} rate table?`
                              )
                            ) {
                              void onDisassociateRateTable(rateTable.external_id);
                            }
                          }}
                        >
                          <DeleteRounded />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </LoadingWrapper>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={Number(rateTables?.data?.length) || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
        <DialogBox
          open={open}
          onClose={handleClose}
          title="Associate Rate Tables"
          description="Select the rate tables to associate."
        >
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox onChange={handleAssociateRateTableSelectAll} />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Date Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allRateTables
                    ?.filter((x) => !rateTables?.data?.find((y) => y.external_id === x.external_id))
                    .map((rateTable) => (
                      <TableRow key={rateTable.external_id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedRateTableExternalIds.indexOf(rateTable.external_id) !== -1}
                            onChange={(event) => handleAssociateRateTableSelectOne(event, rateTable.external_id)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Typography color="textPrimary" variant="body1">
                              {rateTable.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{rateTable.description}</TableCell>
                        <TableCell>{formatDate(rateTable.created_date_utc)}</TableCell>
                        <TableCell>{formatDate(rateTable.last_modified_date_utc)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Button
                sx={{ m: 2 }}
                variant="contained"
                disabled={!selectedRateTableExternalIds?.length}
                onClick={() => {
                  onAssociateRateTables(selectedRateTableExternalIds);
                  setSelectedRateTableExternalIds([]);
                  handleClose();
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedRateTableExternalIds([]);
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </PerfectScrollbar>
        </DialogBox>
      </CardContent>
    </Card>
  );
};

export default RateSetRateTableList;
