import joinUrlParts from "../utils/joinUrlParts";
import { ApiFetch } from "./client";
import { RateCardItem } from "./rateCard";
import { RateTableItem } from "./rateTable";
import { throwOnError } from "./throwOnError";

export interface RateSetListResponse {
  rate_sets: RateSetItem[];
  total_count: number;
}
export interface RateSetItem {
  external_id: string;
  name: string;
  description?: string;
  /** The UTC date-time the rate card was created */
  created_date_utc?: string;
  /** The UTC date-time the rate card was last modified. */
  last_modified_date_utc?: string;
  /** The UTC date-time the rate card is effective from. */
  effective_date_utc: string;
  /** The UTC date-time the rate card expires. */
  expiration_date_utc: string;
}

export type CreatableRateSet = { external_id: string } & EditableRateSet;

export interface EditableRateSet {
  name: string;
  description?: string;
  effective_date_utc: string;
  expiration_date_utc?: string;
}

export interface RateSetApi {
  getAll: (page: number, itemsPerPage: number) => Promise<RateSetListResponse>;
  get: (rateSetId: string) => Promise<RateSetItem>;
  create: (newValue: CreatableRateSet) => Promise<void>;
  update: (rateSetId: string, newValue: EditableRateSet) => Promise<void>;
  remove: (rateSetId: string) => Promise<void>;
  associateRateTables: (
    rateSetId: string,
    rateTableIds: string[]
  ) => Promise<void>;
  disassociateRateTable: (
    rateSetId: string,
    rateTableId: string
  ) => Promise<void>;
  getRateTables: (rateSetId: string) => Promise<RateTableItem[]>;
  getRateCards: (rateSetId: string) => Promise<RateCardItem[]>;
}

const rateSet = (client: ApiFetch) => {
  const getAll = async (
    page = 0,
    itemsPerPage = 10
  ): Promise<RateSetListResponse> => {
    const results = await client(
      `rate-sets?page=${page}&itemsPerPage=${itemsPerPage}`
    );

    await throwOnError(results);

    return await results.json();
  };

  const get = async (rateSetId: string): Promise<RateSetItem> => {
    const results = await client(joinUrlParts("rate-sets", rateSetId));

    await throwOnError(results);

    return await results.json();
  };

  const create = async (newValue: CreatableRateSet): Promise<void> => {
    const results = await client("rate-sets", {
      method: "POST",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const update = async (
    rateSetId: string,
    newValue: EditableRateSet
  ): Promise<void> => {
    const results = await client(joinUrlParts("rate-sets", rateSetId), {
      method: "PUT",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const remove = async (rateSetId: string): Promise<void> => {
    const results = await client(joinUrlParts("rate-sets", rateSetId), {
      method: "DELETE",
    });

    await throwOnError(results);
  };

  const associateRateTables = async (
    rateSetId: string,
    rateTableIds: string[]
  ): Promise<void> => {
    const results = await client(
      joinUrlParts("rate-sets", rateSetId, "rate-tables"),
      {
        method: "PATCH",
        body: JSON.stringify({ rate_tables: rateTableIds }),
      }
    );

    await throwOnError(results);
  };

  const disassociateRateTable = async (
    rateSetId: string,
    rateTableId: string
  ): Promise<void> => {
    const results = await client(
      joinUrlParts("rate-sets", rateSetId, "rate-tables", rateTableId),
      {
        method: "DELETE",
      }
    );

    await throwOnError(results);
  };

  const getRateTables = async (rateSetId: string): Promise<RateTableItem[]> => {
    const results = await client(
      joinUrlParts("rate-sets", rateSetId, "rate-tables")
    );

    await throwOnError(results);

    return await results.json();
  };

  const getRateCards = async (rateSetId: string): Promise<RateCardItem[]> => {
    const results = await client(
      joinUrlParts("rate-sets", rateSetId, "rate-cards")
    );

    await throwOnError(results);

    return await results.json();
  };

  return {
    getAll,
    get,
    create,
    update,
    remove,
    associateRateTables,
    disassociateRateTable,
    getRateTables,
    getRateCards,
  };
};

export default rateSet;
