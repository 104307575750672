import React from "react";
import { Box, Container, TablePagination } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { ApiClient } from "../../api/client";
import { ZoneSetListResponse } from "../../api/zoneSet";
import ListToolbar from "../../components/ListToolbar";
import { useAsync } from "../../components/Loading/useAsync";
import { useLoadableState } from "../../components/Loading/useLoadableState";
import ZoneSetListResults from "../../components/zoneSets/ZoneSetListResults";

const ZoneSetList = () => {
  const navigate = useNavigate();
  const [zoneSetsResponse, setZoneSetsResponse] =
    useLoadableState<ZoneSetListResponse>({
      zone_sets: [],
      total_count: 0,
    });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const getPagedZoneSetsResponse = React.useCallback(
    (client: ApiClient) => client.zone.set.getAll(page, rowsPerPage),
    [page, rowsPerPage]
  );

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => {
    const newRowsPerPage = parseInt(event?.target.value || "", 10);

    setRowsPerPage(isNaN(newRowsPerPage) ? 25 : newRowsPerPage);
    setPage(0);
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  useAsync(getPagedZoneSetsResponse, setZoneSetsResponse);

  return (
    <>
      <Helmet>
        <title>Zone Set List</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ListToolbar
            title="Zone Sets"
            newButtonTitle="Add zone set"
            newUrl="/app/zoneSets/new"
          />
          <Box sx={{ pt: 3 }}>
            <ZoneSetListResults
              zoneSetsResponse={zoneSetsResponse}
              onClick={(x) => navigate(`/app/zoneSet/${x}`)}
            >
              <TablePagination
                component="div"
                count={Number(zoneSetsResponse?.data?.total_count) || 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </ZoneSetListResults>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ZoneSetList;
