import {
  Card,
  Table,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { JobItem } from "../../api/job";
import PerfectScrollbar from "react-perfect-scrollbar";

interface ScheduledJobListProps {
  jobs: JobItem[];
}

const ScheduledJobList = ({ jobs }: ScheduledJobListProps) => (
  <Card>
    <PerfectScrollbar>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Job Type</TableCell>
              <TableCell>Data Type</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Total Items</TableCell>
              <TableCell>Date Scheduled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((x) => (
              <TableRow key={x.job_id}>
                <TableCell>{x.job_type}</TableCell>
                <TableCell>{x.data_type}</TableCell>
                <TableCell>
                  <Link to={`/app/rateTable/${x.external_id}`}>
                    {x.external_id}
                  </Link>
                </TableCell>
                <TableCell>{x.total_items}</TableCell>
                <TableCell>{x.date_scheduled}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  </Card>
);

export default ScheduledJobList;
