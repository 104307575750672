const slashRegex = /^\/?(.*?)\/?$/gm;

const removeSlashes = (value: string, isFirst: boolean, isLast: boolean) => {
  if (isFirst) {
    return value[value.length - 1] === "/"
      ? value.substring(0, value.length - 1)
      : value;
  }

  if (isLast) {
    return value[0] === "/" ? value.substring(1, value.length) : value;
  }

  return value.replace(slashRegex, "$1");
};

/** Join a URL path */
const joinUrlParts = (...pieces: string[]): string => {
  if (pieces.some((x) => x === undefined)) {
    throw new Error("Cannot join undefined url parts");
  }

  if (pieces.some((x) => x === "")) {
    throw new Error("Cannot join empty url parts");
  }

  return pieces
    .map((x, i) => removeSlashes(x, i === 0, i === pieces.length - 1))
    .join("/");
};

export default joinUrlParts;
