import JobList from "./JobList";

const jobRoutes = [
  {
    path: "jobs",
    exact: true,
    element: JobList,
  },
];

export default jobRoutes;
