import { CircularProgress, TableCell, TableRow } from "@mui/material";

interface LoadingTableRowProps {
  colSpan: number;
}

const LoadingTableRow = ({ colSpan }: LoadingTableRowProps) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ textAlign: "center" }}>
        <CircularProgress color="inherit" />
      </TableCell>
    </TableRow>
  );
};

export default LoadingTableRow;
