import GetRates from "./GetRates";

const getRatesRoutes = [
  {
    path: "getRates",
    exact: true,
    element: GetRates,
  },
];

export default getRatesRoutes;
