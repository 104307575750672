import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { EditableRateCard } from "../../api/rateCard";
import RateCardDetailsToolbar from "../../components/rateCards/RateCardDetailsToolbar";
import RateCardDisplay from "../../components/rateCards/RateCardDisplay";
import RateCardEdit from "../../components/rateCards/RateCardEdit";
import { RateCardItem } from "../../api/rateCard";
import { RateSetItem } from "../../api/rateSet";
import { useLoadableState, useLoadingInfoState } from "../../components/Loading/useLoadableState";
import { performAsyncAction, useAsync } from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import { useSnackbar } from "notistack";
import AssociatedRateSetList from "../../components/rateSets/AssociatedRateSetList";
import useApiClient from "../../api/useApiClient";
import { ApiClient } from "../../api/client";
import { RateCardVersionListResponse } from "../../api/publishing";
import RateCardVersions from "../../components/rateCards/RateCardVersions";
import RateCardCodeControls from "../../components/rateCards/RateCardCodeControls";

const RateCardDetails = (_props: React.PropsWithoutRef<{}>) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };
  const [rateCard, setRateCard] = useLoadableState<RateCardItem>({
    external_id: "",
    name: "",
  });
  const [rateSets, setRateSets] = useLoadableState<RateSetItem[]>([]);
  const [editableRateCard, setEditableRateCard] = React.useState<EditableRateCard | undefined>(undefined);
  const [rateCardCode, setRateCardCode] = useLoadableState<string | undefined>(undefined);
  const [rateCardVersions, setRateCardVersions] = useLoadableState<RateCardVersionListResponse>({ publishes: [] });
  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const getRateCard = React.useCallback((client: ApiClient) => client.rate.card.get(id), [id]);
  const getRateSetsForRateCard = React.useCallback((client: ApiClient) => client.rate.card.getRateSets(id), [id]);
  const getRateCardVersions = React.useCallback((client: ApiClient) => client.rate.card.getRateCardVersions(id), [id]);
  const getRateCardCode = React.useCallback((client: ApiClient) => client.rate.card.getCode(id), [id]);

  useAsync(getRateCard, setRateCard);
  useAsync(getRateSetsForRateCard, setRateSets);
  useAsync(getRateCardVersions, setRateCardVersions);
  useAsync(getRateCardCode, setRateCardCode);

  return (
    <>
      <Helmet>
        <title>Rate Card Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <RateCardDetailsToolbar
            name={rateCard.data.name || "Loading..."}
            canDelete={!!rateCard && !!rateSets && (rateSets.data.length || 0) === 0}
            editing={editableRateCard !== undefined}
            onEdit={() => {
              setEditableRateCard(
                rateCard && {
                  name: rateCard.data.name || "",
                  description: rateCard.data.description || "",
                }
              );
            }}
            onSave={() => {
              if (rateCard && editableRateCard) {
                performAsyncAction(
                  () => client.rate.card.update(id, editableRateCard),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => {
                    setRateCard({
                      ...rateCard,
                      data: {
                        ...rateCard.data,
                        ...editableRateCard,
                      },
                    });
                    setEditableRateCard(undefined);
                  }
                );
              }
            }}
            onCancel={() => {
              setEditableRateCard(undefined);
            }}
            onDelete={() => {
              if (window.confirm("Are you sure you want to delete this?")) {
                performAsyncAction(
                  () => client.rate.card.remove(id),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/rateCards")
                );
              }
            }}
          />
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                {rateCard &&
                  (editableRateCard ? (
                    <RateCardEdit value={editableRateCard} onChange={(x) => setEditableRateCard(x)} />
                  ) : (
                    <RateCardDisplay rateCard={rateCard} />
                  ))}
              </Grid>

              <Grid item container lg={8} md={6} xs={12} spacing={3} alignContent="flex-start">
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <AssociatedRateSetList
                    rateSets={rateSets}
                    onAssociateRateSets={(rateSetIds) =>
                      performAsyncAction(
                        () => client.rate.card.associateRateSets(id, rateSetIds),
                        setLoadingInfo,
                        enqueueSnackbar,
                        () => performAsyncAction(getRateSetsForRateCard(client), setRateSets, enqueueSnackbar)
                      )
                    }
                    onDisassociateRateSet={(rateSetId) =>
                      performAsyncAction(
                        () => client.rate.card.disassociateRateSet(id, rateSetId),
                        setLoadingInfo,
                        enqueueSnackbar,
                        () => performAsyncAction(getRateSetsForRateCard(client), setRateSets, enqueueSnackbar)
                      )
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={12} md={12} xs={12}>
                  <RateCardCodeControls
                    id={id}
                    code={rateCardCode}
                    onRemoveLogic={() =>
                      client.rate.card.removeCode(id).then(() => setRateCardCode((x) => ({ ...x, data: undefined })))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <RateCardVersions versions={rateCardVersions}></RateCardVersions>
              </Grid>
              <Grid item lg={8} md={6} xs={12}></Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default RateCardDetails;
