import { Card, CardContent, Grid, TextField } from "@mui/material";
import { CreatableZoneFile } from "../../api/zoneFile";

interface ZoneFileCreateProps {
  value: CreatableZoneFile;
  onChange: (newValue: CreatableZoneFile) => void;
}

const ZoneFileCreate = ({ value, onChange, ...rest }: ZoneFileCreateProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form autoComplete="off" noValidate {...rest}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify an id of the zone file"
                label="External ID"
                name="external_id"
                onChange={handleChange}
                required
                value={value.external_id}
                variant="outlined"
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Name of the zone file"
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={value.name}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="If set, the zone file will inherit all the data from the specified zone file"
                label="Base Zone File External ID"
                name="base_zone_file_external_id"
                onChange={handleChange}
                value={value.base_zone_file_external_id}
                variant="outlined"
                inputProps={{ maxLength: 64 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please describe the zone file"
                label="Description"
                name="description"
                onChange={handleChange}
                value={value.description}
                variant="outlined"
                multiline={true}
                rows={4}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default ZoneFileCreate;
