import PublishingDetails from "./PublishingDetails";

const publishingRoutes = [
  {
    path: "publishing",
    exact: true,
    element: PublishingDetails,
  },
];

export default publishingRoutes;
