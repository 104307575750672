import NewZoneSet from "./NewZoneSet";
import ZoneSetDetails from "./ZoneSetDetails";
import ZoneSetList from "./ZoneSetList";

const zoneSetRoutes = [
  {
    path: "zoneSets",
    exact: true,
    element: ZoneSetList,
  },
  {
    path: "zoneSets/new",
    exact: true,
    element: NewZoneSet,
  },
  { path: "zoneSet/:id", exact: true, element: ZoneSetDetails },
];

export default zoneSetRoutes;
