import React, { useEffect, useState } from "react";
import { Alert, Box, Card, CardContent, Collapse, IconButton, Tooltip, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

interface RateResultViewerProps {
  result: any;
}

const RateResultViewer = ({ result }: RateResultViewerProps) => {
  const [response, setResponse] = useState<string>("Rate Result");
  const [status, setStatus] = useState<number>();
  const [explanation, setExplanation] = useState<string>("");
  const [explanationHidden, setExplanationHidden] = React.useState(true);

  useEffect(() => {
    setResponse(JSON.stringify(result?.body?.rates ?? result?.body, null, 2));
    setExplanation(JSON.stringify(result?.body?.metadata?.explanation, null, 2));
    setStatus(result?.status);
  }, [result]);

  const getStatusText = (status: number | undefined): string =>
    !status
      ? ""
      : status >= 200 && status < 300
      ? "OK"
      : status >= 400 && status < 500
      ? "Bad Request"
      : status >= 500
      ? "Error"
      : "";

  return (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ pb: 2 }}>
              <Typography color="textPrimary" variant="h3">
                Rate Response
              </Typography>
            </Box>
            <Box sx={{ pb: 0.5 }}>
              <Alert severity={!status ? "info" : status === 200 ? "success" : "warning"}>
                Status - {getStatusText(status)}
              </Alert>
            </Box>
          </Box>
          <Box
            style={{
              maxHeight: 800,
              overflowY: "scroll",
              background: "#f5f5f5",
              border: 1,
              borderStyle: "solid",
              borderRadius: 2,
              borderColor: "gray",
            }}
          >
            <pre style={{ padding: "5px 5px", minHeight: "45px" }}>
              {response?.length > 0 ? response : "Send rates request to view rates."}
            </pre>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              pt: 2,
            }}
            onClick={() => setExplanationHidden(!explanationHidden)}
          >
            <Box>
              <Typography color="textPrimary" variant="h5">
                Explanation
              </Typography>
            </Box>
            <Box>
              <Tooltip title={explanationHidden ? "View explanation" : "Hide explanation"}>
                <IconButton onClick={() => setExplanationHidden(!explanationHidden)} size="small">
                  {explanationHidden ? <ArrowDownward /> : <ArrowUpward />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Collapse in={!explanationHidden}>
            <Box
              style={{
                marginTop: 10,
                maxHeight: 800,
                overflowY: "scroll",
                background: "#f5f5f5",
                border: 1,
                borderStyle: "solid",
                borderRadius: 2,
                borderColor: "gray",
              }}
            >
              <pre style={{ padding: "5px 5px", minHeight: "35px" }}>
                {explanation?.length > 0 ? explanation : "Rating explanations."}
              </pre>
            </Box>
          </Collapse>
        </CardContent>
      </Card>
    </>
  );
};

export default RateResultViewer;
