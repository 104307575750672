import "./App.css";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import theme from "./theme";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";
import { GlobalStyles } from "@mui/material";
import { LoginCallback, Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { oktaConfig } from "./config";
import { useCallback } from "react";
import MainLayout from "./components/MainLayout";
import DashboardLayout from "./components/DashboardLayout";
import { RequiredAuth } from "./components/RequiredAuth";

import rateCardRoutes from "./pages/RateCards/routes";
import rateSetRoutes from "./pages/RateSets/routes";
import rateTableRoutes from "./pages/RateTables/routes";
import zoneSetRoutes from "./pages/ZoneSets/routes";
import zoneFileRoutes from "./pages/ZoneFiles/routes";
import publishingRoutes from "./pages/Publishing/routes";
import ratingLogicRoutes from "./pages/RatingLogic/routes";
import jobRoutes from "./pages/Jobs/routes";
import getRatesRoutes from "./pages/GetRates/routes";
import Dashboard from "./pages/Dashboard";
import CarrierList from "./pages/Carriers/CarrierList";
import NotFound from "./pages/NotFound";

const oktaAuth = new OktaAuth(oktaConfig);
oktaAuth.start().catch(x => console.error(x)); // start the service
oktaAuth.tokenManager.on("renewed", (key, token, oldToken) => {
  console.log("=============== TOKEN RENEWED");
  console.log(key);
  console.log(token);
  console.log(oldToken);
});

const routes = [
  ...rateCardRoutes,
  ...rateSetRoutes,
  ...rateTableRoutes,
  ...zoneSetRoutes,
  ...zoneFileRoutes,
  ...ratingLogicRoutes,
  ...publishingRoutes,
  ...jobRoutes,
  ...getRatesRoutes,
  { path: "dashboard", element: Dashboard },
  { path: "carriers", element: CarrierList },
  { path: "*", element: NotFound },
];

const RoutedApp = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = useCallback(
    async (_: unknown, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || "/", window.location.origin));
    },
    [navigate]
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SnackbarProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route path="404" element={<NotFound />} />
            <Route path="" element={<Navigate to="/app/carriers" />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Route>
          <Route path="/app" element={<RequiredAuth />}>
            <Route path="" element={<DashboardLayout />}>
              {routes.map((route) => (
                <Route key={route.path} path={route.path} element={<route.element />} />
              ))}
            </Route>
          </Route>
        </Routes>
      </SnackbarProvider>
    </Security>
  );
};

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <GlobalStyles
            styles={{
              "*": {
                boxSizing: "border-box",
                margin: 0,
                padding: 0,
              },
              html: {
                WebkitFontSmoothing: "antialiased",
                MozOsxFontSmoothing: "grayscale",
                height: "100%",
                width: "100%",
              },
              body: {
                backgroundColor: "#f4f6f8",
                height: "100%",
                width: "100%",
              },
              a: {
                textDecoration: "none",
              },
              "#root": {
                height: "100%",
                width: "100%",
              },
            }}
          />
          <RoutedApp />
        </HelmetProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
