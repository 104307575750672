import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { RateCardItem } from "../../api/rateCard";
import { useLoadableState, useLoadingInfoState } from "../../components/Loading/useLoadableState";
import { performAsyncAction, useAsync } from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import { useSnackbar } from "notistack";
import useApiClient from "../../api/useApiClient";
import { ApiClient } from "../../api/client";
import LogicEditor from "../../components/ratingLogic/LogicEditor";
import CodeUpload from "../../components/ratingLogic/CodeUpload";

const RateCardCode = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };
  const [rateCard, setRateCard] = useLoadableState<RateCardItem>({
    external_id: "",
    name: "",
  });

  const [rateCardCode, setRateCardCode] = useLoadableState<string | undefined>(undefined);

  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const getRateCard = React.useCallback((client: ApiClient) => client.rate.card.get(id), [id]);
  const getRateCardCode = React.useCallback((client: ApiClient) => client.rate.card.getCode(id), [id]);

  useAsync(getRateCard, setRateCard);
  useAsync(getRateCardCode, setRateCardCode);

  const downLoadInfo = {
    filename: `${rateCard?.data.external_id?.replace(/ /g, "")}_code.json`,
  };

  const onUploadCodeFile = (data: string) => client.rate.card.setCode(id, data, true);

  const onUploadRawCode = (rawCode: string) => client.rate.card.setCode(id, rawCode);

  const onDownloadData = () => client.rate.card.downloadCode(id);

  return (
    <>
      <Helmet>
        <title>Rating Logic</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Grid container>
          <Grid item xs={8}>
            <Container maxWidth={false} data-color-mode="dark">
              <Box>
                <Typography variant="h1">{rateCard.data.name} Rating Logic</Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                <LogicEditor
                  code={rateCardCode?.data || ""}
                  onUploadCode={(rawString) => {
                    performAsyncAction(
                      () => onUploadRawCode(rawString),
                      setLoadingInfo,
                      enqueueSnackbar,
                      () => navigate(-1)
                    );
                  }}
                />
              </Box>
            </Container>
          </Grid>
          <Grid item xs={4}>
            <Container maxWidth={false}>
              <Box>
                <Typography variant="h1">File Upload</Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                <CodeUpload
                  downloadInfo={downLoadInfo}
                  onDownloadData={onDownloadData}
                  onUploadCodeFile={(data) => {
                    performAsyncAction(
                      () => onUploadCodeFile(data),
                      setLoadingInfo,
                      enqueueSnackbar,
                      () => navigate(-1)
                    );
                  }}
                />
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default RateCardCode;
